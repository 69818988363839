import React, {useEffect, useState} from 'react'
import {Button, Dialog} from "@mui/material";
import TextFieldItem from "../../../TextFieldItem";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        textTransform: "uppercase",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    wrapperTextFieldItem: {
        display: "flex",
        gap: 10
    },
    underTitle: {
        fontWeight: 700
    },
    img: {
        cursor: "pointer",
        color: "#4db1d3"
    },
    wrapperButton: {
        display: "flex",
        gap: 10,
        marginTop: 30,
        justifyContent: "end"
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#4db1d3",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}));


const DialogAddLabel = ({values, addLabel,}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const [label, setLabel] = useState({
        label: '',
        disable: true,
    })


    const handleClose = () => {
        setOpen(false);
    };

    const handleLabel = (val) => {
        setLabel(pre => ({...pre, label: val, disable: false}))
    }

    const handleSave = () => {
        handleClose()
        if (addLabel) {
            if (label.label.length === 0) {
                return addLabel(null)
            }
            addLabel(label.label)
        }
    }


    useEffect(() => {
        if (values?.treeItem?.label === label.label) {
            setLabel(pre => ({...pre, disable: true,}))
        }
    }, [label.label]);

    useEffect(() => {
        if (values?.treeItem?.label) {
            setLabel(pre => ({...pre, label: values.treeItem.label,}))
        }
    }, [])


    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
            <span className={cl.title}>Insert alias</span>
            <CloseIcon onClick={handleClose}/>
        </div>
        <div className={cl.wrapperTextFieldItem}>
            <span className={cl.underTitle}> Label:</span>
            <TextFieldItem values={label.label} setValues={handleLabel} autoFocus={true}/>
        </div>
        <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleSave} disabled={label.disable}>
                OK
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}

export default DialogAddLabel