import { getLoadContext, getLoadSimilarApi } from "../../BlockPlugin/API";
import { checkGrammar, checkSpelling,  } from "../API";
import { compositeDecorator, updateDecorator } from "./decorator";
import {  AtomicBlockUtils, EditorState } from "draft-js";
import handleValueState from "@zert-packages/utils/handleValueState";
import { API_ROOT_CALLS } from "../components/mediaBlockRenderer";

export const findTranslatorWord = async (newText, newEditorState, mylocale, el,values, setValues,) => {

  if (newText.length === 0) {
    return setValues(pre => ({ ...pre, translator: null }));
  }

  const elementInfo = el?.elementInfo ? el.elementInfo.id : -1;
  let findWord = [];
  const resultSimilar = await getLoadSimilarApi(elementInfo, mylocale, newText);
  findWord = [...resultSimilar];

  const resultContext = await getLoadContext(elementInfo, mylocale, newText);
  findWord = [...findWord, ...resultContext];

  const resultCheckGrammar = await checkGrammar(mylocale, newText);
  const resultCheckSpelling = await checkSpelling(mylocale, newText)

  if(resultCheckSpelling && resultCheckSpelling.length > 0  ){
    const newDecorator =  updateDecorator(resultCheckSpelling);

     setValues(pre => ({ ...pre,
       decorator: newDecorator
     }));
  }

  if (findWord.length > 0) {
    return setValues(pre => ({ ...pre, translator: findWord, newContent: newText ,  }));
  }

  setValues(pre => ({ ...pre, translator: null,   }));
};




export const addImg = (id, values, setValues, EditorState) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const contentState = values.editorState.getCurrentContent();
  // src: URL.createObjectURL(img)
  //'Immutable',
  //'IMMUTABLE'
  //      src: `${API_ROOT_CALLS}/image/getImageById/${id}?jwtToken=${accessToken}`,
  const contentStateWithEntity = contentState.createEntity(
    'image',
    'IMMUTABLE',
    {
      addImg: true,
      src: id,
      width: '100%',
      height: '100%'
    }
  );




  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(
    values.editorState,
    {currentContent: contentStateWithEntity}
  );
  const res = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    ' '
  )

  handleValueState(EditorState.moveFocusToEnd(res), 'editorState', setValues)
  //setValues
}
