import { GetEnv } from '@zert-packages/react/EnvProvider';
import React, { createContext, useContext, useEffect, useState } from 'react';

const PortalContext = createContext();

type PortalContextType = {
  identifier: string;
  manifest: string;
  logos: {
    svg: string;
    png: string;
  };
  favicons: {
    ico: string;
    pngs: string;
  };
  background: string;
};

export const usePortal = (): PortalContextType => {
  const context = useContext(PortalContext);
  if (!context) {
    throw new Error('useBrand must be used within a BrandProvider');
  }

  return context as PortalContextType;
};

export function PortalProvider({ manifest, logos, favicons, bgs, children }) {
  const [portalInfo, setPortalInfo] = useState();
  const portal = GetEnv('REACT_APP_PORTAL');

  useEffect(() => {
    if (!portal) {
      return;
    }

    const faviconRegex = new RegExp(`${portal}/favicon_(\\d+)?\\.png`);

    const newPortal = {
      identifier: portal,
      manifest: null,
      logos: {
        svg: null,
        png: null
      },
      favicons: {
        ico: null,
        pngs: null
      },
      background: null
    };

    if (manifest) {
      newPortal.manifest = manifest
        .keys()
        .filter((filePath) => filePath.includes(`${portal}`))[0]
        .slice(1);
    }
    if (logos) {
      newPortal.logos.svg = logos
        .keys()
        .filter((filePath) => filePath.includes(`${portal}`) && filePath.endsWith('.svg'))[0]
        .slice(1);

      newPortal.logos.png = logos
        .keys()
        .filter((filePath) => filePath.includes(`${portal}`) && filePath.endsWith('.png'))[0]
        .slice(1);
    }
    if (favicons) {
      newPortal.favicons.ico = favicons
        .keys()
        .filter((filePath) => filePath.includes(`${portal}/favicon.ico`))[0]
        .slice(1);

      newPortal.favicons.pngs = favicons.keys().filter((filePath) => faviconRegex.test(filePath));
    }
    if (bgs) {
      newPortal.background = bgs
        .keys()
        .filter((filePath) => filePath.includes(`${portal}`))[0]
        .slice(1);
    }

    setPortalInfo(newPortal);
  }, [portal]);

  useEffect(() => {
    if (!portalInfo) {
      return;
    }

    const manifest = document.getElementById('manifest');
    if (manifest && portalInfo.manifest) {
      manifest.href = `${process.env.PUBLIC_URL}/resources${portalInfo.manifest}`;
    }

    const logoSvg = document.getElementById('logoSvg');
    if (logoSvg && portalInfo.logos && portalInfo.logos.svg) {
      logoSvg.src = `${process.env.PUBLIC_URL}/resources${portalInfo.logos.svg}`;
      logoSvg.style.display = 'block';
    }

    const faviconElement = document.getElementById(`favicon`);
    if (faviconElement && portalInfo.favicons && portalInfo.favicons.ico) {
      faviconElement.href = `${process.env.PUBLIC_URL}/resources${portalInfo.favicons.ico}`;
    }

    const setFaviconHref = (size) => {
      const faviconElement = document.getElementById(`favicon${size}`);
      if (faviconElement) {
        faviconElement.href = `${process.env.PUBLIC_URL}/resources${portalInfo.favicons.pngs
          .filter((filePath) => filePath.includes(`${portal}/favicon_${size}.png`))[0]
          .slice(1)}`;
      }
    };

    if (portalInfo.favicons && portalInfo.favicons.pngs) {
      portalInfo.favicons.pngs.forEach((filePath) => {
        const sizeMatch = filePath.match(/favicon_(\d+)\.png/);
        if (sizeMatch) {
          const size = parseInt(sizeMatch[1], 10);
          setFaviconHref(size, filePath);
        }
      });
    }
  }, [portalInfo]);

  return <PortalContext.Provider value={portalInfo}>{children}</PortalContext.Provider>;
}
