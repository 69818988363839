import React, { useEffect, useRef, useState, useContext } from "react";
import { Arrow, Group, Rect, Text, Transformer } from "react-konva";
import { CanvasContext } from "./WrapperCanvas";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogHotspot from "../dialog/dialoghotsport/DialogHotspot";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import DialogNewForm from "../dialog/DialogNewForm";
import DialogArrow from "../dialog/DialogArrow";
import { useElementOpen } from "@zert-packages/components/ElementTile/utils/useElementOpen";



const CanvasItem = ({ el, wrapper, context }) => {
  const { width, height } = wrapper;
  const groupRef = useRef(null);
  const trRef = React.useRef(null);
  const { open } = useElementOpen();
  const { values, setValues } = useContext(CanvasContext);
  const { setValues: setContextValues, values: valuesContext } = useContext(context || PanelContext);
  const selected = values.selectedItem?.id === el.id;
  const [transformer, setTransform] = useState({
    width: el.width,
    height: el.height
  });

  const adobePositionX = (position) => {
    if (position < 0) {
      return 0;
    }
    const getWidth = +width - el.width - 1;
    if (getWidth < position) {
      return getWidth;
    }
    return position;
  };

  const adobePositionY = (position) => {
    if (position < 0) {
      return 0;
    }
    const getHeight = +height - el.height - 1;
    if (getHeight < position) {
      return getHeight;
    }
    return position;
  };


  const handleYellowGroupDragMove = () => {
    if (groupRef.current) {
      handleSaveBlock()
      const { x, y } = groupRef.current.getAbsolutePosition();
      const adobeX = adobePositionX(x);
      const adobeY = adobePositionY(y);
      groupRef.current.x(adobeX);
      groupRef.current.y(adobeY);
      handleItem({ x: adobeX, y: adobeY, defaultX: adobeX, defaultY: adobeY });
    }
  };
  // console.log('values item', values);

  const handleItem = (val) => {
    const res = values.item.reduce((acu, cur) => {
      if (cur.id === el.id) {
        return [...acu, { ...cur, ...val }];
      }
      return [...acu, cur];
    }, []);
    setValues(pre => ({ ...pre, item: res }));
  };


  const handleSelected = (e) => {
    e.evt.preventDefault()
    e.evt.stopPropagation()
    setValues(pre => ({ ...pre, "selectedItem": el }));

    if(valuesContext.sparePartContext) {
      setContextValues(prev => ({...prev, selectedPart: valuesContext.parts?.find(part => part.position === el.position)}))
      return
    }

    handleSaveBlock()
    const phrase = el.phrase
    if(phrase){
      return open({...el, ...phrase, mimeType: phrase.mimeType, versionId: phrase.versionId });
    }
    if (selected && el.type === "Arrow") {
      return renderInExceptionRoot(DialogArrow, {
        setValues: setContextValues,
        el
      });
    }
    if (selected && el.editor) {
      return renderInExceptionRoot(DialogNewForm, {
        setValues: setContextValues,
        setTransform,
        el: {
          ...el,
          width: transformer.width,
          height: transformer.height
        },
        edit: true
      });
    }
    if (selected && (el.type === "Ellips" || el.type === "Rektangel")) {
      renderInExceptionRoot(DialogHotspot, {
        setValues: setContextValues,
        el: { ...el }
      });
    }
  };

  const findPosition = (position, positionElement, x) => {
    if(x && !el.phrase){
      return position;
    }
    if (el.type === "Ellips" || el.type === "Rektangel") {
      return positionElement / 2 - 5;
    }
    return position;
  };

  const adobeSize = (size) => {

    const h = size.height;
    const w = size.width;

    const abobe = { ...size, height: h > 40 ? 40 : h, width: w < 100 ? 100 : w };

    if (el.type !== "Arrow") {
      return size;
    }
    return abobe;

  };

  const findColor = (stroke, fill) => {
    if (stroke && stroke.length > 0) {
      return stroke;
    }
    if (fill) {
      return selected ? "#01A1C7" : "";
    }
    return selected ? "#01A1C7" : "#000000";
  };

  const rotateBack = (rotationValue) => {
    return (rotationValue % 360 + 360) % 360;
  };

  const handleTransform = (e) => {
    const node = trRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const width = node.width() * scaleX;
    const height = node.height() * scaleY;
    handleSaveBlock()
    setTransform({
      width: +width.toFixed(0),
      height: +height.toFixed(0)
    });

    const rotatedValue = +e.target.rotation().toFixed(0);
    const convertedValue = rotateBack(rotatedValue);

    handleItem({
      rotate: convertedValue,
      angle: convertedValue,
      width: +width.toFixed(0),
      height:  +height.toFixed(0),
      defaultWidth:   +width.toFixed(0),
      defaultHeight: +height.toFixed(0),
    });
  };

  const handleWidthChange = (newWidth) => {

  };
  const handleSaveBlock = () => {
   if(valuesContext.changeBlock){
     return
   }
    setContextValues(pre => ({...pre, changeBlock: true }))
  }

  const handleBoxFunc = (old, newB) => {
    const oldBox = adobeSize(old);
    let newBox = adobeSize(newB);
    if (newBox.width < 5 || newBox.height < 4) {
      return oldBox;
    }
    return newBox;
  };

  useEffect(() => {
    if (selected && el.editor) {
      trRef.current.nodes([groupRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected, el]);

  const rotate = +el.rotate ? el.rotate : 0;
  const adjustedRotate = (rotate % 360 + 360) % 360;

  return (<div>
    {(el.type === "group" || el.type === "Ellips" || el.type === "Rektangel") && <Group
      onMouseEnter={e => {
        const container = e.target.getStage().container();
        container.style.cursor = "move";
      }}
      rotationDeg={adjustedRotate}
      ref={groupRef}
      width={+el.width}
      height={+el.height}
      onClick={handleSelected}
      x={+el.x}
      y={+el.y}
      draggable={!valuesContext.sparePartContext}
      onDragMove={valuesContext.sparePartContext
          ? () => {}
          : e => handleYellowGroupDragMove(e)}
      onDblClick={() => {
        if(valuesContext.sparePartContext) {
          setContextValues(prev => ({
            ...prev,
            dialog: {
              open: true,
              type: "editHotspot",
              hotspotToEdit: el
            }
          }))
        }
      }}
    >
      <Rect
        width={+el.width}
        height={+el.height}
        cornerRadius={el.cornerRadius}
        strokeWidth={+el.strokeWidth}
        stroke={findColor(el.strokeColor)}
        fill={findColor(el.fillColor, true)}
        dash={el.dash}
        opacity={1}
      />
      {el.text && <Text
         x={findPosition(5, el.width, true)}
        y={findPosition(10, el.height)}
        width={+el.width - 10}
          align={el.align?.toLowerCase()}
        // align={'center'}
        fontSize={12}
        fontFamily="Calibri"
        text={el.text}
        fill="black"
      />}
      {el.label && <Text
        x={5}
        y={el.height - 14}
        fontSize={12}
        fontFamily="Calibri"
        text={el.label}
        fill="black"
      />}
    </Group>}

    {el.type === "Arrow" && <Arrow
      hitStrokeWidth={25}
      points={el.points}
      x={+el.x}
      y={+el.y}
      scaleX={el.scaleX}
      scaleY={el.scaleY}
      fill={el.lineColor}
      stroke={el.lineColor}
      strokeWidth={+el.line}
      ref={groupRef}
      rotationDeg={+el.rotate}
      dash={el.dash}
      draggable
      onClick={handleSelected}
      onTap={handleSelected}
      onWidthChange={handleWidthChange}
      onDragEnd={(e) => {
        handleItem({
          x: e.target.x(),
          y: e.target.y()
        });
      }}
      onTransformEnd={(e) => {
        const node = e.target.attrs;

        const scaleY = +(node.scaleX.toFixed());
        handleItem({
          scaleY: scaleY < 1 ? 1 : scaleY
        });
      }}
    />}

    {selected && el.editor &&
      <Transformer
        ref={trRef}
        onTransform={handleTransform}
        boundBoxFunc={(old, newB) => {
          const res = handleBoxFunc(old, newB);
          return res;
        }}
      />
    }
  </div>);
};

export default CanvasItem;