import { SET_ROUTE } from '@zert-packages/actions/coreReducers';
import {
  handlePost,
  handlePostFile,
  handlePostParamAsString,
  handleQuery,
  handleQueryFileAsResultHeaders,
  handleText,
  openPathBody
} from '@zert-packages/actions/api';

const FETCH_RA_FAILURE = 'FETCH_RA_FAILURE';

const STORE_SUCCESS = 'STORE_SUCCESS';
const STORE_START = 'STORE_START';
const STORE_ERROR = 'STORE_ERROR';

const PUBLICATION = 'PUBLICATION';
const PUBLICATION_FETCH = 'PUBLICATION_FETCH';

const LAYOUT = 'LAYOUT';
const LAYOUT_FETCH = 'LAYOUT_FETCH';

const MACHINE = 'MACHINE';
const MACHINE_FETCH = 'MACHINE_FETCH';
const MACHINE_LOCALES = 'MACHINE_LOCALES';
const MACHINE_LOCALES_START = 'MACHINE_LOCALES_START';

const PUBLICATION_LOG = 'PUBLICATION_LOG';
const PUBLICATION_LOG_START = 'PUBLICATION_LOG_START';

const PUBLICATION_PORTAL = 'PUBLICATION_PORTAL';
const PUBLICATION_PORTAL_FETCH = 'PUBLICATION_PORTAL_FETCH';

const UPDATE_PRODUCT_NICKNAME = 'UPDATE_PRODUCT_NICKNAME';

const TOC = 'TOC';
const TOC_FETCH = 'TOC_FETCH';

const IMAGE = 'IMAGE';
const IMAGE_FETCH = 'IMAGE_FETCH';

const MY_LOCALE = 'MY_LOCALE';
const MY_LOCALE_FETCH = 'MY_LOCALE_FETCH';
const MY_LOCALE_STORE_START = 'MY_LOCALE_STORE_START';
const MY_LOCALE_STORE_DONE = 'MY_LOCALE_STORE_DONE';

const MY_USER_FETCH = 'MY_USER_FETCH';
const MY_USER_FETCH_START = 'MY_USER_FETCH_START';

const USER_FETCH = 'USER_FETCH';
const USER_FETCH_START = 'USER_FETCH_START';

const MY_PUBLICATIONS_DONE = 'MY_PUBLICATIONS';
const ZERT_PUBLICATIONS_DONE = 'ZERT_PUBLICATIONS_DONE';
const ZERT_PUBLICATIONS_START = 'ZERT_PUBLICATIONS_START';
const MY_PUBLICATIONS_START = 'MY_PUBLICATIONS_START';

const MY_FOLDER_DONE = 'MY_FOLDER_DONE';
const MY_FOLDER_START = 'MY_FOLDER_START';

const MY_PUBLICATIONS_GROUP_START = 'MY_PUBLICATIONS_GROUP_START';
const MY_PUBLICATIONS_GROUP = 'MY_PUBLICATIONS_GROUP';

const PUBLICATION_CONTENT = 'PUBLICATION_CONTENT';
const PUBLICATION_CONTENT_START = 'PUBLICATION_CONTENT_START';
const PUBLICATION_CONTENT_CLEAR = 'PUBLICATION_CONTENT_CLEAR';

const MY_COMPANY_FETCH = 'MY_COMPANY_FETCH';
const MY_COMPANY_FETCH_START = 'MY_COMPANY_FETCH_START';
const MY_COMPANIES_FETCH = 'MY_COMPANIES_FETCH';
const MY_COMPANIES_FETCH_START = 'MY_COMPANIES_FETCH_START';

const COMPANY_TO_LIST_FETCH_START = 'COMPANY_TO_LIST_FETCH_START';
const COMPANY_TO_LIST_FETCH = 'COMPANY_TO_LIST_FETCH';

const USER_TO_LIST_FETCH_START = 'USER_TO_LIST_FETCH_START';
const USER_TO_LIST_FETCH = 'USER_TO_LIST_FETCH';

const CUSTOMER_FETCH_START = 'CUSTOMER_FETCH_START';
const CUSTOMER_FETCH = 'CUSTOMER_FETCH';
const CUSTOMER_CLEAR = 'CUSTOMER_CLEAR';

const MY_NICKNAMES_FETCH = 'MY_NICKNAMES_FETCH';
const MY_NICKNAMES_FETCH_START = 'MY_NICKNAMES_FETCH_START';

const MY_NICKNAME_STORE = 'MY_NICKNAME_STORE';

const MY_USERSLIST_FETCH = 'MY_USERSLIST_FETCH';
const MY_USERSLIST_FETCH_START = 'MY_USERSLIST_FETCH_START';
const MY_USERSLIST_CLEAR = 'MY_USERSLIST_CLEAR';

const USERSLIST_TO_COMPANY_FETCH = 'USERSLIST_TO_COMPANY_FETCH';
const USERSLIST_TO_COMPANY_START = 'USERSLIST_TO_COMPANY_START';

const FILE_UPLOAD = 'FILE_UPLOAD';
const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
const FILE_UPLOAD_DONE = 'FILE_UPLOAD_DONE';

const FETCH_SETTINGS = 'FETCH_SETTINGS';
const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
const FETCH_SETTINGS_STORE_START = 'FETCH_SETTINGS_STORE_START';
const FETCH_SETTINGS_STORE_DONE = 'FETCH_SETTINGS_STORE_DONE';

const FETCH_CUSTOMER_START = 'FETCH_CUSTOMER_START';
const FETCH_CUSTOMER_DONE = 'FETCH_CUSTOMER_DONE';

const AREA_LIST_START_ACTION = 'AREA_LIST_START';
const AREA_LIST_ACTION = 'AREA_LIST';

const COUNTRY_LIST_START_ACTION = 'COUNTRY_LIST_START';
const COUNTRY_LIST_ACTION = 'COUNTRY_LIST';

export const FETCH_MY_NOTIFICATIONS = 'FETCH_MY_NOTIFICATIONS';
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const FETCH_MY_NOTIFICATIONS_START = 'FETCH_MY_NOTIFICATIONS_START';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_START = 'FETCH_NOTIFICATION_START';

export const GENERATING_MESSAGE = 'GENERATING_MESSAGE';

const FETCH_START = 'FETCH_START';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';

export const isLoading = (state, queryId) => state.isLoading[queryId] === true;
export const getError = (state, queryId) => state.isError[queryId];
export const getData = (state, queryId) => state[queryId];

export const initialState = {
  toc: null,
  publication: null,
  mylocale: null,
  mycompany: null,
  error: null,
  countries: null,
  areas: null,
  isLoading: {},
  isError: {},
  loading: null,
  notificationList: [],
  notification: null,
  publicationlist: null,
  publicationBundle: null,
  zertPublicationlist: null,
  publicationsbygroup: new Map(),
  nicknames: new Map(),
  myuser: null,
  user: null,
  companieslist: null,
  userslist: null,
  previewMessage: {},
  image: null,
  machine: null,
  machineLocales: [],
  company: null,
  customer: null
};

export const fetchStart = (queryId) => ({ type: FETCH_START, queryId });
export const fetchSuccess = (queryId, data) => ({ type: FETCH_SUCCESS, queryId, data });
export const fetchFailure = (queryId, error) => ({ type: FETCH_FAILURE, queryId, error });

export const generatingMessage = (message, uuid) => ({
  type: GENERATING_MESSAGE,
  payload: { uuid, message }
});

const fetchRA_Error = (error) => ({
  type: FETCH_RA_FAILURE,
  payload: { error }
});

const fetchSTORE_Error = (error) => ({
  type: STORE_ERROR,
  payload: { error }
});

const updateProduct = (elementId, nickname) => ({
  type: UPDATE_PRODUCT_NICKNAME,
  payload: { elementId, nickname }
});

const fetchElementStored = (id) => ({
  type: STORE_SUCCESS,
  payload: { id }
});
const fetchElementStoreStart = () => ({
  type: STORE_START,
  payload: {}
});

const fetchPublicationPortal = (publicationPortal) => ({
  type: PUBLICATION_PORTAL,
  payload: { publicationPortal }
});
const fetchPublicationPortalStart = () => ({
  type: PUBLICATION_PORTAL_FETCH,
  payload: {}
});

const fetchToc = (toc) => ({
  type: TOC,
  payload: { toc }
});
const fetchTocStart = () => ({
  type: TOC_FETCH,
  payload: {}
});

const fetchPublication = (publication) => ({
  type: PUBLICATION,
  payload: { publication }
});

const fetchPublicationStart = () => ({
  type: PUBLICATION_FETCH,
  payload: {}
});

const fetchLayout = (layout) => ({
  type: LAYOUT,
  payload: { layout }
});

const fetchLayoutStart = () => ({
  type: LAYOUT_FETCH,
  payload: {}
});

const fetchMachine = (machine) => ({
  type: MACHINE,
  payload: { machine }
});

const fetchLog = (log) => ({
  type: PUBLICATION_LOG,
  payload: { log }
});

const fetchMachineLocalesStart = () => ({
  type: MACHINE_LOCALES_START,
  payload: {}
});

const fetchMachineLocales = (machineLocales) => ({
  type: MACHINE_LOCALES,
  payload: { machineLocales }
});

const fetchMachineStart = () => ({
  type: MACHINE_FETCH,
  payload: {}
});

const fetchMachineLog = () => ({
  type: PUBLICATION_LOG_START,
  payload: {}
});

const fetchImage = (image) => ({
  type: IMAGE,
  payload: { image }
});
const fetchImageStart = () => ({
  type: IMAGE_FETCH,
  payload: {}
});

const fetchSettingsDone = (settings) => ({
  type: FETCH_SETTINGS,
  payload: { settings }
});
const fetchSettingsStart = () => ({
  type: FETCH_SETTINGS_START,
  payload: {}
});
const fetchStoreSettingsDone = () => ({
  type: FETCH_SETTINGS_STORE_DONE,
  payload: {}
});
const fetchStoreSettingsStart = () => ({
  type: FETCH_SETTINGS_STORE_START,
  payload: {}
});

const fetchMyLocale = (locale) => ({
  type: MY_LOCALE,
  payload: { locale }
});
const fetchMyStartLocale = () => ({
  type: MY_LOCALE_FETCH,
  payload: {}
});
const fetchStoreMyLocaleStart = () => ({
  type: MY_LOCALE_STORE_START,
  payload: {}
});
const fetchStoreMyLocaleDone = (locale) => ({
  type: MY_LOCALE_STORE_DONE,
  payload: { locale }
});

export const fetchMyUserDone = (user) => ({
  type: MY_USER_FETCH,
  payload: { user }
});

export const fetchUserDone = (user) => ({
  type: USER_FETCH,
  payload: { user }
});
export const fetchMyUserStart = () => ({
  type: MY_USER_FETCH_START,
  payload: {}
});

export const fetchUserStart = () => ({
  type: USER_FETCH_START,
  payload: {}
});

const fetchMyCompanyDone = (company) => ({
  type: MY_COMPANY_FETCH,
  payload: { company }
});
const fetchMyCompanyStart = () => ({
  type: MY_COMPANY_FETCH_START,
  payload: {}
});

const fetchMyCompaniesDone = (companieslist) => ({
  type: MY_COMPANIES_FETCH,
  payload: { companieslist }
});

export const updateCustomerList = (company) => ({
  type: COMPANY_TO_LIST_FETCH,
  payload: { company }
});

export const updateUserList = (user) => ({
  type: USER_TO_LIST_FETCH,
  payload: { user }
});

export const updateCustomer = (company) => ({
  type: CUSTOMER_FETCH,
  payload: { company }
});

export const clearCustomer = () => ({
  type: CUSTOMER_CLEAR,
  payload: {}
});

const fetchMyCompaniesStart = () => ({
  type: MY_COMPANIES_FETCH_START,
  payload: {}
});

export const fetchUserToListStart = () => ({
  type: USER_TO_LIST_FETCH_START,
  payload: {}
});

export const fetchCompanyToListStart = () => ({
  type: COMPANY_TO_LIST_FETCH_START,
  payload: {}
});

export const fetchCompanyStart = () => ({
  type: CUSTOMER_FETCH_START,
  payload: {}
});

export const fetchMyUsersListDone = (userslist) => ({
  type: MY_USERSLIST_FETCH,
  payload: { userslist }
});
export const fetchMyUsersListStart = () => ({
  type: MY_USERSLIST_FETCH_START,
  payload: {}
});

export const fetchUsersToCompanyDone = (userslist) => ({
  type: USERSLIST_TO_COMPANY_FETCH,
  payload: { userslist }
});
export const fetchUsersToCompanyStart = () => ({
  type: USERSLIST_TO_COMPANY_START,
  payload: {}
});
export const clearMyUserList = (userslist) => ({
  type: MY_USERSLIST_CLEAR,
  payload: { userslist }
});

const fetchMyPublicationsStart = () => ({
  type: MY_PUBLICATIONS_START,
  payload: {}
});

const fetchZertPublicationsStart = () => ({
  type: ZERT_PUBLICATIONS_START,
  payload: {}
});
const fetchMyPublications = (publicationlist) => ({
  type: MY_PUBLICATIONS_DONE,
  payload: { publicationlist }
});

const fetchZertPublications = (publicationlist) => ({
  type: ZERT_PUBLICATIONS_DONE,
  payload: { publicationlist }
});

const fetchMyNicknamesStart = () => ({
  type: MY_NICKNAMES_FETCH_START,
  payload: {}
});
const fetchMyNicknames = (nicknames) => ({
  type: MY_NICKNAMES_FETCH,
  payload: { nicknames }
});

// export function setUser(userToEdit) {
//     return fetchMyUserDone(userToEdit);
// }

export function setCompany(companyToEdit) {
  return fetchMyCompanyDone(companyToEdit);
}

const fetchGroupPublications = (publicationlist, groupid) => ({
  type: MY_PUBLICATIONS_GROUP,
  payload: { publicationlist, groupid }
});
const fetchGroupPublicationsStart = () => ({
  type: MY_PUBLICATIONS_GROUP_START,
  payload: {}
});

const fetchPublicationContent = (publication) => ({
  type: PUBLICATION_CONTENT,
  payload: { publication }
});
const fetchPublicationContentStart = () => ({
  type: PUBLICATION_CONTENT_START,
  payload: {}
});
export const clearPublicationContent = () => ({
  type: PUBLICATION_CONTENT_CLEAR,
  payload: {}
});

const fetchMyFolder = (folderlist) => ({
  type: MY_FOLDER_DONE,
  payload: { folderlist }
});
const fetchMyFolderStart = () => ({
  type: MY_FOLDER_START,
  payload: {}
});

const fetchFileUpload = (file, uuid) => ({
  type: FILE_UPLOAD,
  payload: { file, uuid }
});
export const fetchFileUpload_DONE = () => ({
  type: FILE_UPLOAD_DONE,
  payload: {}
});
const fetchFileUpload_Begin = () => ({
  type: FILE_UPLOAD_START,
  payload: {}
});

export const fetchCustomer_Begin = () => ({
  type: FETCH_CUSTOMER_START,
  payload: {}
});
export const fetchCustomer_Done = (customer) => ({
  type: FETCH_CUSTOMER_DONE,
  payload: { customer }
});

const AREA_LIST = (areas) => ({
  type: AREA_LIST_ACTION,
  payload: { areas }
});
const AREA_LIST_START = () => ({
  type: AREA_LIST_START_ACTION,
  payload: {}
});

const COUNTRY_LIST = (countries) => ({
  type: COUNTRY_LIST_ACTION,
  payload: { countries }
});
const COUNTRY_LIST_START = () => ({
  type: COUNTRY_LIST_START_ACTION,
  payload: {}
});

export function loadCountries() {
  return function (dispatch) {
    handleQuery('/publication/getCountryList')
      .then((countries) => {
        dispatch(COUNTRY_LIST(countries));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(COUNTRY_LIST_START());
  };
}

export function loadAreas() {
  return function (dispatch) {
    handleQuery('/publication/getAreaList')
      .then((areas) => {
        dispatch(AREA_LIST(areas));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(AREA_LIST_START());
  };
}

export function getUser() {
  return function (dispatch) {
    handleQuery('/common/myUserName')
      .then((user) => {
        dispatch(fetchMyUserDone(user));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyUserStart());
  };
}

export function getMyCustomer() {
  return function (dispatch) {
    handleQuery('/publication/getMyCustomer')
      .then((company) => {
        dispatch(fetchCustomer_Done(company));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCustomer_Begin());
  };
}

export function storeMyUser(data) {
  return function (dispatch) {
    handlePost('/common/storeMyUser/', data)
      .then((id) => {
        dispatch(fetchElementStored(id));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function uploadFile(filedata, uuid) {
  return function (dispatch) {
    handlePostFile('/publication/uploadImageFile', filedata)
      .then((info) => {
        dispatch(fetchFileUpload(info, uuid));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchFileUpload_Begin());
  };
}

export function loadSyncInfo() {
  return handleQuery('/publication/getSyncInfo');
}

export function uploadXLSFile(filedata) {
  return function (dispatch) {
    handlePostFile('/publication/uploadXLSFile', filedata)
      .then(() => {
        dispatch(fetchFileUpload_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchFileUpload_Begin());
  };
}

export function removeUser(userId, companyId) {
  return function (dispatch) {
    handleQuery(`/publication/removeUser/${userId}`)
      .then(() => {
        dispatch(loadUserList(companyId));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function removeCompany(companyId) {
  return function (dispatch) {
    handleQuery(`/publication/removeGroup/${companyId}`)
      .then(() => {
        dispatch(loadCustomerList());
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function addEditUser(companyId, data) {
  return function (dispatch) {
    handlePost(`/publication/addEditUser/${companyId}`, data)
      .then(() => {
        dispatch(loadUserList(companyId));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function storeNickName(elementId, nickName) {
  return function (dispatch) {
    handlePostParamAsString(`/publication/storeNickName/${elementId}/`, nickName)
      .then((id) => {
        dispatch(fetchElementStored(id));
      })
      .then(() => {
        dispatch(updateProduct(elementId, nickName));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function removeNickName(elementId) {
  return function (dispatch) {
    handlePostParamAsString(`/publication/removeNickName/${elementId}/`)
      .then((id) => {
        dispatch(fetchElementStored(id));
      })
      .then(() => {
        dispatch(updateProduct(elementId, ''));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function cloneWebPublications() {
  return function (dispatch) {
    handleQuery('/publication/cloneWebPublications/');
    return dispatch(fetchElementStoreStart());
  };
}

export function storeFolders(folders) {
  return function (dispatch) {
    handlePost('/publication/storeFolders', folders)
      .then(() => {
        dispatch(getMyFolders());
      })
      .then(() => {
        dispatch(getWebPublications());
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function storeCompany(data, refresh) {
  return function (dispatch) {
    handlePost('/publication/addEditCustomer/', data)
      .then(() => {
        if (refresh) {
          dispatch(getWebPublications());
        }
        dispatch(loadCustomerList());
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function getSettings() {
  return function (dispatch) {
    handleQuery('/publication/getSetting')
      .then((settings) => {
        dispatch(fetchSettingsDone(settings));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchSettingsStart());
  };
}

export function storeSettings(settings) {
  return function (dispatch) {
    handlePost('/publication/storeSetting', settings)
      .then(() => {
        dispatch(fetchSettingsStart());

        return handleQuery('/publication/getSetting');
      })
      .then(() => {
        dispatch(fetchSettingsDone(settings));
        dispatch(fetchStoreSettingsDone());
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchStoreSettingsStart());
  };
}

export function storeMyLocale(locale) {
  return function (dispatch) {
    handleText(`/common/storeMyLocale/${locale}`)
      .then((id) => {
        dispatch(fetchStoreMyLocaleDone(locale));
        window.location.reload();
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchStoreMyLocaleStart());
  };
}

export function loadMyLocale() {
  return function (dispatch) {
    handleText('/common/loadMyLocale')
      .then((myLocale) => {
        dispatch(fetchMyLocale(myLocale));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyStartLocale());
  };
}

export const downloadFromAzure = async (id, name, getApproved) => {
  const res = await handleQuery(`/publication/basis/getDocumentFromAzureById/${id}/${getApproved}`);
  const link = document.createElement('a');
  link.href = res;
  link.download = name;
  link.target = '_blank';
  link.click();
  link.remove();
};

export const openAttachment = async (id, name, getApproved) => {
  const res = await handleQueryFileAsResultHeaders(`${openPathBody + id}/${getApproved}`);
  const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
  link.remove();
};

export const downloadAttachment = async (path) => {
  const res = await handleQueryFileAsResultHeaders(path);
  const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
  const link = document.createElement('a');
  link.href = url;
  link.download = res.headers['content-disposition'].replace('inline;filename=', '');
  link.click();
  link.remove();
};

export function getWebPublications() {
  return function (dispatch) {
    handleQuery('/publication/getPublicationFolderContent')
      .then((publicationlist) => {
        dispatch(fetchMyPublications(publicationlist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyPublicationsStart());
  };
}

export function getGroupPublications(groupid) {
  return function (dispatch) {
    handleQuery(`/publication/getPublicationFolderContent/${groupid}`)
      .then((publicationlist) => {
        dispatch(fetchGroupPublications(publicationlist, groupid));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchGroupPublicationsStart());
  };
}

export function getMyFolders() {
  return function (dispatch) {
    handleQuery('/publication/getPublicationFolders')
      .then((publicationlist) => {
        dispatch(fetchMyFolder(publicationlist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyFolderStart());
  };
}

export function loadToc(id, locale, elementId) {
  return function (dispatch) {
    handleText(`/publication/getTocById/${id}/${locale}/${elementId}`)
      .then((toc) => {
        dispatch(fetchToc(toc));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchTocStart());
  };
}

export function loadTocStandalone(id, locale, elementId) {
  return handleText(`/publication/getTocById/${id}/${locale}/${elementId}`);
}

export function loadPublication(id, locale, elementId) {
  return function (dispatch) {
    handleText(`/publication/getPublicationById/${id}/${locale}/${elementId}`)
      .then((publication) => {
        dispatch(fetchPublication(publication));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchPublicationStart());
  };
}

export function loadLatestLayoutToPreview() {
  return function (dispatch) {
    handleQuery(`/publication/getLatestLayout`)
      .then((layout) => {
        dispatch(fetchLayout(layout));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchLayoutStart());
  };
}

export function getImageContentById(versionId) {
  return function (dispatch) {
    handleText(`/publication/getImageContentById/${versionId}`)
      .then((image) => {
        dispatch(fetchImage(image));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchImageStart());
  };
}

export function loadLog(publicationId) {
  return function (dispatch) {
    handleQuery(`/publication/loadLog/${publicationId}`)
      .then((log) => {
        dispatch(fetchLog(log));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMachineLog());
  };
}

export function getPartsLocale(elementId) {
  return function (dispatch) {
    handleQuery(`/publication/getPartsLocale/${elementId}`)
      .then((machineLocales) => {
        dispatch(fetchMachineLocales(machineLocales));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMachineLocalesStart());
  };
}

export function loadSparePart(elementId, locale) {
  return function (dispatch) {
    handleQuery(`/publication/loadSparePart/${elementId}/${locale}`)
      .then((machine) => {
        dispatch(fetchMachine(machine));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMachineStart());
  };
}

export function loadPublicationBundle(id) {
  return function (dispatch) {
    handleQuery(`/publication/getPublicationContent/${id}`)
      .then((publication) => {
        dispatch(fetchPublicationContent(publication));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchPublicationContentStart());
  };
}

export function removeBasis(publicationId, id, anchor, uuid) {
  return handleQuery(`/publication/removeBasis/${publicationId}/${id}/${uuid}/${anchor}`);
}

export function uploadBasesToDoc(files, fileInfos, publicationBundleId, approve) {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`files`, file);
    formData.append(`fileInfos`, JSON.stringify(fileInfos[index]));
  });

  return handlePostFile(`/publication/basis/uploadBasis/${publicationBundleId}/${approve}`, formData);
}

export function uploadBasisToDoc(
  e,
  filename,
  publicationBundleId,
  publicationId,
  anchor,
  type,
  sendNotification,
  approve
) {
  const file = e.target && e.target.files ? e.target.files[0] : e;
  const fd = new FormData();
  if (!file) return;
  fd.append('files', file, filename);

  return handlePostFile(
    `/publication/uploadBasis/${publicationBundleId}/${publicationId}/${anchor}/${type}/${sendNotification}/${approve}`,
    fd
  );
}

export function getApprovedBasis(id) {
  return handleQuery(`/publication/getApprovedBasis/${id}`);
}

export function approveBasis(publicationId, id, anchor, type) {
  return handleQuery(`/publication/approveBasis/${publicationId}/${id}/${anchor}/${type}`);
}

export function declineBasis(publicationId, id, anchor, type) {
  return handleQuery(`/publication/declineBasis/${publicationId}/${id}/${anchor}/${type}`);
}

export function uploadBasis(files, publicationId, id, anchor, type) {
  const fd = new FormData();
  if (!files) return;
  for (const file of files) {
    fd.append('files', file, file.name);
  }

  return handlePostFile(
    `/publication/uploadBasis/${publicationId}/${id}/${anchor}/${type == null ? '-1' : type}/false/true`,
    fd
  );
}

export function uploadBasisToAzure(files, publicationId, id, anchor, type) {
  const fd = new FormData();
  if (!files) return;
  for (const file of files) {
    fd.append('files', file, file.name);
  }

  return handlePostFile(
    `/publication/basis/${publicationId}/${id}/${anchor}/${type == null ? '-1' : type}/false/true`,
    fd
  );
}

export function loadAllPublicationsList() {
  return function (dispatch) {
    let list = [];
    handleQuery('/publication/getAllPublicationsList')
      .then((publicationlist) => {
        list = publicationlist;
        // dispatch(fetchMyPublications(publicationlist));
        return handleQuery('/publication-links/getWebPublications');
      })
      .then((publicationlist) => {
        const newList = publicationlist.list.reduce((total, element, index) => {
          const found = list.find(
            (lelement) =>
              lelement.properties?.['zert:ElementId'] &&
              element.elementInfo.id === parseInt(lelement.properties['zert:ElementId'])
          );
          if (found) {
            return [...total, { ...found, ...element, propspub: found.properties, elementId: element.elementInfo.id }];
          }
          return total;
        }, []);
        dispatch(fetchZertPublications(newList));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyPublicationsStart());
  };
}

export function loadAllZertPublicationsList() {
  return function (dispatch) {
    handleQuery('/publication-links/getWebPublications')
      .then((publicationlist) => {
        dispatch(fetchZertPublications(publicationlist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchZertPublicationsStart());
  };
}

export function loadCustomerList() {
  return function (dispatch) {
    handleQuery('/publication/getCustomerList')
      .then((companieslist) => {
        dispatch(fetchMyCompaniesDone(companieslist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyCompaniesStart());
  };
}

export function loadCustomerToList(id) {
  return function (dispatch) {
    handleQuery(`/publication/getCustomerById/${id}`)
      .then((customer) => {
        dispatch(updateCustomerList(customer));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCompanyToListStart());
  };
}

export function loadCustomer(id) {
  return function (dispatch) {
    handleQuery(`/publication/getCustomerById/${id}`)
      .then((customer) => {
        dispatch(updateCustomer(customer));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCompanyStart());
  };
}

export function loadUserList(companyId) {
  return function (dispatch) {
    handleQuery(`/publication/getUserList/${companyId}`)
      .then((userslist) => {
        dispatch(fetchMyUsersListDone(userslist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyUsersListStart());
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.queryId]: true }
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.queryId]: false }
        // [action.queryId]: action.data // Assume queryId maps to a property in the state
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.queryId]: false },
        isError: { ...state.isError, [action.queryId]: action.error }
      };

    case MY_LOCALE:
      return {
        ...state,
        mylocale: action.payload.locale,
        loading: false
      };
    case LAYOUT:
      return {
        ...state,
        selectedLayout: action.payload.layout
      };
    case MY_LOCALE_FETCH:
      return {
        ...state,
        loading: true
      };

    case COUNTRY_LIST_ACTION:
      return {
        ...state,
        countries: action.payload.countries
      };

    case AREA_LIST_ACTION:
      return {
        ...state,
        areas: action.payload.areas
      };

    case MY_USER_FETCH:
      return {
        ...state,
        myuser: action.payload.user
      };

    case USER_FETCH:
      return {
        ...state,
        user: action.payload.user,
        loading: false
      };

    case UPDATE_PRODUCT_NICKNAME:
      return {
        ...state,
        publicationlist:
          state.publicationlist &&
          state.publicationlist.map((pub) =>
            pub.id === action.payload.elementId
              ? {
                  ...pub,
                  properties: {
                    ...pub.properties,
                    nickname: action.payload.nickname
                  }
                }
              : pub
          )
      };

    case USER_FETCH_START:
      return {
        ...state,
        loading: true,
        user: null
      };

    case GENERATING_MESSAGE:
      const id = action.payload.uuid;
      return {
        ...state,
        previewMessage: { ...state.previewMessage, [id]: action.payload.message }
      };
    case MY_COMPANY_FETCH:
      return {
        ...state,
        mycompany: action.payload.company
      };
    case MY_COMPANIES_FETCH:
      return {
        ...state,
        loading: false,
        companieslist: action.payload.companieslist
      };

    case COMPANY_TO_LIST_FETCH:
      return {
        ...state,
        companieslist:
          state.companieslist &&
          state.companieslist.map((comp) =>
            comp.elementId === action.payload.company.elementId ? action.payload.company : comp
          )
      };

    case USER_TO_LIST_FETCH:
      return {
        ...state,
        customer: {
          ...state.customer,
          userslist:
            state.customer && state.customer.userslist
              ? (() => {
                  const userIndex = state.customer.userslist.findIndex(
                    (user) => user.elementId === action.payload.user.elementId
                  );
                  if (userIndex !== -1) {
                    return state.customer.userslist.map((user, index) =>
                      index === userIndex ? action.payload.user : user
                    );
                  }
                  return [action.payload.user, ...state.customer.userslist];
                })()
              : null
        }
      };

    case CUSTOMER_FETCH:
      return {
        ...state,
        customer: action.payload.company,
        loading: false
      };

    case CUSTOMER_FETCH_START:
      return {
        ...state,
        loading: true,
        customer: null
      };

    case CUSTOMER_CLEAR:
      return {
        ...state,
        customer: null
      };

    case USERSLIST_TO_COMPANY_START:
      return {
        ...state,
        loading: true
      };
    case USERSLIST_TO_COMPANY_FETCH: {
      return {
        ...state,
        loading: false,
        customer: state.customer
          ? { ...state.customer, userslist: action.payload.userslist }
          : { userslist: action.payload.userslist }
      };
    }

    case MY_USERSLIST_FETCH_START:
      return {
        ...state,
        loading: true
      };
    case MY_USERSLIST_FETCH:
      return {
        ...state,
        loading: false,
        userslist: action.payload.userslist
      };
    case MY_USERSLIST_CLEAR:
      return {
        ...state,
        userslist: action.payload.userslist
      };

    case PUBLICATION_PORTAL:
      return {
        ...state,
        publicationPortal: action.payload.publicationPortal,
        loading: false
      };
    case PUBLICATION_PORTAL_FETCH:
      return {
        ...state,
        publicationPortal: null,
        loading: true
      };

    case TOC:
      return {
        ...state,
        toc: action.payload.toc,
        loading: false
      };
    case TOC_FETCH:
      return {
        ...state,
        toc: null,
        loading: true
      };

    case PUBLICATION:
      return {
        ...state,
        publication: action.payload.publication,
        loading: false
      };
    case PUBLICATION_FETCH:
      return {
        ...state,
        publication: null,
        loading: true
      };

    case MACHINE_LOCALES:
      return {
        ...state,
        machineLocales: action.payload.machineLocales,
        loading: false
      };
    case MACHINE_LOCALES_START:
      return {
        ...state,
        machineLocales: [],
        loading: true
      };

    case MACHINE:
      return {
        ...state,
        machine: action.payload.machine,
        loading: false
      };
    case MACHINE_FETCH:
      return {
        ...state,
        machine: null,
        loading: true
      };

    case IMAGE:
      return {
        ...state,
        image: action.payload.image,
        loading: false
      };
    case IMAGE_FETCH:
      return {
        ...state,
        image: null,
        loading: true
      };

    case MY_PUBLICATIONS_DONE:
      return {
        ...state,
        loading: false,
        publicationlist: action.payload.publicationlist
      };
    case ZERT_PUBLICATIONS_DONE:
      return {
        ...state,
        loading: false,
        zertPublicationlist: action.payload.publicationlist
      };
    case SET_ROUTE:
      return {
        ...state,
        oldRoute: action.payload.route
      };

    case MY_PUBLICATIONS_GROUP:
      state.publicationsbygroup.set(action.payload.groupid, action.payload.publicationlist);
      return {
        ...state,
        loading: false,
        publicationsbygroup: new Map(state.publicationsbygroup)
      };

    case MY_FOLDER_DONE:
      return {
        ...state,
        loading: false,
        folderlist: action.payload.folderlist
      };
    case MY_FOLDER_START:
      return {
        ...state,
        loading: true
      };

    case STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.id
      };

    case MY_NICKNAMES_FETCH:
      action.payload.nicknames.map((tuple) => {
        state.nicknames.set(tuple.left, tuple.right);
      });
      return {
        ...state,
        loading: false,
        nicknames: new Map(state.nicknames)
      };
    case MY_NICKNAMES_FETCH_START:
      return {
        ...state,
        loading: true,
        nicknames: new Map()
      };

    case PUBLICATION_CONTENT_START:
      return {
        ...state,
        loading: true,
        publicationBundle: null
      };
    case PUBLICATION_CONTENT:
      return {
        ...state,
        loading: false,
        publicationBundle: action.payload.publication
      };
    case PUBLICATION_CONTENT_CLEAR:
      return {
        ...state,
        publicationBundle: null,
        loading: false
      };

    case PUBLICATION_LOG:
      return {
        ...state,
        log: action.payload.log
      };

    case FILE_UPLOAD_START:
      return {
        ...state,
        upload_uuid: null,
        file: null
      };
    case FILE_UPLOAD:
      return {
        ...state,
        upload_uuid: action.payload.uuid,
        file: action.payload.file
      };
    case FILE_UPLOAD_DONE:
      return {
        ...state,
        upload_uuid: null,
        file: null
      };

    case FETCH_SETTINGS:
      return {
        ...state,
        settingslist: action.payload.settings,
        loading: false
      };

    case MY_PUBLICATIONS_START:
      return {
        ...state,
        loading: true
      };
    case FETCH_CUSTOMER_DONE:
      return {
        ...state,
        mycompany: action.payload.customer
      };
    case FETCH_SETTINGS_STORE_START:
      return {
        ...state,
        loading: true
      };

    case STORE_START:
      return {
        ...state,
        loading: true
      };

    case FETCH_SETTINGS_STORE_DONE:
      return {
        ...state,
        loading: false
      };
    case FETCH_SETTINGS_START:
      return {
        ...state,
        loading: true
      };

    case FETCH_RA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case FETCH_NOTIFICATION:
      return {
        ...state,
        notification: action.payload.notification
      };
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        notificationList: [...state.notificationList, { left: action.payload.notification, right: false }]
      };
    case FETCH_MY_NOTIFICATIONS:
      return {
        ...state,
        notificationList: action.payload.notificationList
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
