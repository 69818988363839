import { FormattedMessage, useIntl } from "react-intl";
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { findNode } from "./findNode";
import { useDispatch, useSelector } from "react-redux";
import {
  generateDetailedXLSRiskAnalysesReport,
  generateRiskAnalisesReport
} from "@zert-packages/actions/riskassesmentActions";
import { RiskRowGroupButton } from '@zert-packages/components/common/dialogs/ReportDialogs/utils/RiskRowGroupDialog';
import { loadColumnsAPI, loadColumnsWithFnAPI } from "@zert-packages/components/common/dialogs/ReportDialogs/API";
import { generalCheckBoxes } from './checkboxes';
import generalRadioBtns from './generalRadioBtns';

export const DETAILED_XLS_REPORT_TYPES = {
  RISK_ASSESSMENT: 0
}
export default function useDetailedXLSReports({ selectedNode, selectedValues, catalog, id }) {
  const isAdmin = useSelector((state) => state.myuser.role === 'administrator');

  const getReportCaption = () => {
    return <FormattedMessage id="reassessment.report" defaultMessage="Risk assessment report" />;
  };
  const caption = getReportCaption();

  const getReportDescription = () => {
    return (
      <FormattedMessage
        id="detailedRaXLS.report.description"
        defaultMessage="This report exports the selected riskassesments as XLS files."
      />
    );
  };
  const description = getReportDescription();

  const getKey = () => {
    return 'detailed-risk-analysis-xls-report';
  };
  const multiSelectors = [];
  const textFields = [];
  const index = 'detailed-xls';
  const dispatch = useDispatch();

  const radiobtns = catalog ? generalRadioBtns : [];
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const selectors = [];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = [{
    name: 'cb_include_archived',
    section: 'scope',
    label: <FormattedMessage id="reports.riskassesment.includeArchied" defaultMessage="Include archived" />,
    checked: false
  }];
  const checkedItemKeys = new Set(radiobtns.map(v => v.name).values())


  for (const checkBox of checkBoxes) {
    checkedItemKeys.add(checkBox.name);
  }

  const generateReport = async (checkedItems, setItems) => {
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

/*     if (id === RA_REPORT_TYPES.OVERVIEW_REPORT) {
      const raTemplates = checkedItems('ra_templates');
      const ids = raTemplates && raTemplates.length ? raTemplates : rrtemplates;
      let rowsList = checkedItems('rrGroupButton');
      if (!rowsList || !rowsList.length) {
        rowsList = await loadColumnsWithFnAPI(checkedItems, rrtemplates);
        // setItems("rrGroupButton" , rowsList);
      }

      selectedIds = {
        riskGroups: rowsList,
        templateElementIds: ids.map((template) => template.id),
        rrCount: checkedItems('rrCount')
      };
    } */

    dispatch(
      generateDetailedXLSRiskAnalysesReport(
        {
          name: caption,
          reportUUID: uuidv4()
        },
        catalog ? catalog.info.versionId : -1,
        //TODO: Not only for id RA_REPORT_TYPES.OVERVIEW_REPORT?
        catalog ? findNode(catalog, selectedNode) : (selectedNode || 'none'),
        catalog ? checkedItems('reports.subnode') : "elements",
        checkedItemKeys.has('cb_include_archived') ? checkedItems('cb_include_archived') : false,
        id,
        selectedIds
      )
    );
  };

  //  console.log("racallback" , selected)

  return {
    index,
    generateReport,
    caption,
    description,
    radiobtns,
    dateSelectors,
    multiSelectors,
    selectors,
    checkBoxes,
    textFields,
    buttons: [],
    key: getKey(),
    disabled: !isAdmin
  };
}
