import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, {createContext, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {useDispatch, useSelector} from 'react-redux';
import {Tabs} from '@mui/material';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import {FormattedMessage, injectIntl} from 'react-intl';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import {getVersionInfo} from '@zert-packages/actions/coreReducers';
import TabsWithVersionController from '@zert-packages/components/common/TabsWithVersionController';
import CategoriesTab from './СategoriesTab';
import EntryTab from './EntryTab';
import {turnOnCLMLoader} from '../TranslationJobPlugin/tjReducers';
import {fetchIndex} from './servicesMiddlewares';
import {storeIndex} from './API';
import {generatePreviewReport} from '../PreviewPlugin/servicesMiddlewares';
import {generateTermDBExport} from '../TermDBPlugin/serviceMiddleware';
import {TabsContext} from "../formatTemplatePlugin/FormatTemplatePlugin";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {useSnackbar} from "notistack";


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width: '100%',
        height: 'Calc(100% - 50px)',
        padding: '10px 12px',
    },
    rootBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '5px'
    },
    bodyContainer: {
        padding: '8px',
        height: 'calc(100% - 48px)',
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '@media (max-width: 900px)': {
            flexDirection: 'column'
        }
    },
    AppBar: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        zIndex: '10',
        overflow: 'hidden',
        paddingRight: 8
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    tabs: {
        minHeight: 18,
        '& .MuiTab-root': {
            padding: '0px 4px 0px 3px',
            minWidth: 'fit-content',
            minHeight: 18,
            background: '#EBEBEB',
            textTransform: "capitalize",
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: '#ffffff',
            background: '#4db1d3',
            outline: 'none'
        },
        '& .MuiTabs-centered': {
            justifyContent: 'left',
            background: '#E3F2F7'
        }
    },
    scrollWrapper: {
        position: 'relative',
        width: '100%%',
        height: '65rem',
        // maxHeight: '100%',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        margin: '2px',
        '@media (max-width: 900px)': {
            width: '100%',
            height: '40%'
        }
    },
    tabsContainer: {
        width: '100%',
        height: 'Calc(100% - 20px)',
        marginTop: 5,
    },
    scrollContainer: {
        position: 'absolute',
        width: '100%',
        maxHeight: '90%',
        overflow: 'auto',
        scrollbarWidth: 'none',
        padding: '16px'
    }
}))

export const IndexContext = createContext(null);

function IndexView(props) {
    const cl = useStyles();
    const CLM = useSelector((state) => state.CLM);
    const snackbar = useSnackbar();
    const {
        match: {params}
    } = props;
    const versionId = getParameterAsInt(params.versionId);
    const dispatch = useDispatch();
    const [chosenTab, setChosenTab] = useState('1');
    const routeVersionId = get(props, 'match.params.versionId', null);
    const [versionManager, versionInfo] = useVersionManager();
    const [values, setValues] = useState({
        expander: 2,
        selectedEntries: -1,
        entries: null,
    })



    const handleTabChange = (event, newValue) => {
        setChosenTab(newValue);
    };
    const saveIndexVersionHandler = async () => {
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="CLM.UploadingFile.Save" defaultMessage="Save"/>
        );
        generatingSnackbar;
        console.log('values', values.entries)
        console.log(`TRY TO STORE WITH VERSION ID: ${versionId}`, {...CLM.index, entries: values.entries});
        await storeIndex(versionId, CLM.index);
        snackbar.closeSnackbar(generatingSnackbar);
    };

    useEffect(() => {
        if (routeVersionId === null) return;
        dispatch(getVersionInfo(routeVersionId));
        dispatch(turnOnCLMLoader());
        dispatch(fetchIndex(versionId));
    }, [routeVersionId, versionId]);


    return (
        <IndexContext.Provider value={{ values, setValues }}>
            <TabsWithVersionController
                canCreateVersion={versionManager.canCreateNewVersion}
                canSaveVersion={versionManager.canSaveVersion}
                canApproveVersion={versionManager.canApproveVersion}
                onCreateNewVersion={versionManager.createNewVersion}
                onApproveVersion={versionManager.approveVersion}
                onSaveVersion={saveIndexVersionHandler}
                canPreview
                onPreview={() => {
                    dispatch(generatePreviewReport(versionInfo.info, -1));
                }}
                canExport
                onExport={() => {
                    dispatch(generateTermDBExport(versionId));
                }}
                tabs={[]}
            />
            <div className={cl.mainContainer}>
                {CLM.index && <>
                    <div style={{height: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs
                                value={chosenTab}
                                className={cl.tabs}
                                onChange={handleTabChange}
                            >
                                <Tab label="Entries" value="1"/>
                                <Tab label="Categories" value="2"/>
                            </Tabs>
                        </Box>
                        <div className={cl.tabsContainer}>
                            {chosenTab === '1' && <EntryTab/>}
                            {chosenTab === '2' && <CategoriesTab/>}
                        </div>
                    </div>
                </>}
            </div>
        </IndexContext.Provider>
    );
}

export default injectIntl(IndexView);
