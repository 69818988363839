import React, { useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";

import {
    createIndex, createInitData, createPart,
    createPhrase, createSoftwareTexts, createTerminologyDatabase,
    createTypeBlock, createTypeTableItem,
    handleActionToolBar, voidValidation,
} from "./utils";
import {BlockItemsContext} from "./AddToolbarDialog";
import PhraseEditor from "../../../../../PhrasePlugin/components/PhraseEditor";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import AddIcon from "@mui/icons-material/Add";
import AbstractWrapper from "@zert-packages/components/InfoElement/components/AbstractWrapper";
import ImgTypeWrapper from "../../../../../ImagePlugin/ImgTypeWrapper";
import CustomTable from "../../../CustomTable";
import {useHistory} from "react-router-dom";
import BlockFormat from "./BlockFormat";
import CreateSoftwareTexts from "./CreateSoftwareTexts";
import SwitchSetting from "./MetaDataWrapper";
import CreatePart from "./CreatePart";
import CreateImage from "./CreateImage";


const useStyles = makeStyles((theme) => ({
    wrapperBlockItems: {
        minHeight: 42,
        paddingRight: 5,
        marginBottom: 10,
        cursor: "default"
    },
    container: {
        padding: 5
    },
    wrapperAddToolbar: {
        display: "grid",
        gridTemplateColumns: "25px 1fr",
        marginTop: 8
    },
    wrapperTextField: {
        display: "grid",
        gridColumnGap: 10,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5
    },
    label: {
        fontWeight: 700
    },
    addImgButton: (props) => ({
        height: 16,
        width: 16,
        paddingTop: 3,
        backgroundColor: "#59C1DB",
        color: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        fontSize: 15,
        fontWeight: 900,
        paddingBottom: 4,
        cursor: props.blockClick ? "not-allowed" : "pointer"
    })
}));


const CreateItems = ({
                         blockCreate,
                         setBlockCreate,
                         values,
                         it,
                         setValues,
                         setTableData,
                         setOpenToolbarDialog,
                         setSelected,
                         createRoot,
                         saveImg
                     }) => {
    const cl = useStyles();
    const createType = blockCreate.createType
    const type = 'zert-' + createType
    const {myLanguage, activeLocales} = useSelector((state) => state);
    const snackbar = useSnackbar();
    const [name, setName] = useState("");
    const [createBlock, setCreateBlock] = useState({
        loadingInit: false,
        loadingCreate: false,
        actionType: '',
        table: null,
        description: '',
        softwareTextsStrategy: 'ST - Backend',
        part: {
            language: null,
            articleNumber: null,
            format: null,
            function: null,
            machineType: null,
        },
        imageRoot: null,
        tableFormat: '',
    });
    const [selectedItems, setSelectedItems] = useState(null)
    const error = name.length === 0;
    const parentId = createRoot ? -1 : values?.tableDefault.elementInfo.versionId
    const [img, setImg] = useState({
        blockItem: null,
    })
    const errorImg = img.blockItem && img.blockItem.elementInfo.name.length === 0
    const history = useHistory();
    const styleEditor = createRoot ? {border: '1px solid #000', 'border-radius': 5}: {}

    const handleTable = (table) => {
        setCreateBlock(pre => ({...pre, table}))
    }

    const handleName = (val) => {
        setName(val);
    };

    const handleNameImg = (val) => {
        setImg(pre => ({
            ...pre, blockItem: {
                ...pre.blockItem,
                elementInfo: {...pre.blockItem.elementInfo, name: val}
            }
        }))
    }
    const handleBlock = (val, name) => {
        setCreateBlock(pre => ({...pre, [name]: val}))
    }

    const createInitBlock = async () => {
        const myLocale = myLanguage.value
        setCreateBlock(pre => ({...pre, loadingInit: true}));
        if (type === "zert-phrase") {
            return  createPhrase(snackbar, myLocale, parentId)
        }
        if (type === "zert-block") {
            return createTypeBlock(snackbar, myLocale, parentId, createRoot)
        }
        if (type === "zert-table") {
            return createTypeTableItem(snackbar, myLocale, parentId, )
        }
        if (type === "zert-index") {
            return createIndex(snackbar, myLocale, parentId)
        }
        if(type === "zert-softwaretexts"){
            return createSoftwareTexts(snackbar, myLocale, parentId)
        }
        if(type === "zert-terminology-database"){
            return createTerminologyDatabase(snackbar, myLocale, parentId)
        }
        if(type === "zert-part"){
            return createPart(snackbar, myLocale, parentId)
        }
        handleName("");
        setCreateBlock(pre => ({...pre, loadingInit: false}));
    }


    const handleElement = async (typeSave, type, item, snackbar) => {
        // console.log('1111', {createBlock, name, createRoot, img})
       console.log('2222', voidValidation(createBlock, type, blockCreate.phraseEditor.phrase, name, createRoot, img))
        // return

        if(voidValidation(createBlock, type, blockCreate.phraseEditor.phrase, name, createRoot)){
            return
        }

        setCreateBlock(pre => ({...pre, loadingCreate: true}))

        if (createRoot && type === "zert-image" ) {
            return  saveImg(createBlock.imageRoot)
        }

        let resultInit = null
        if(type !== "zert-image" ){
            resultInit = await createInitBlock()
            if(!resultInit){
                return
            }
        }


        await handleActionToolBar(typeSave, type,  snackbar, blockCreate, setBlockCreate, activeLocales, myLanguage,
            resultInit, values, it, setValues, setTableData, img.blockItem, name, createBlock, setOpenToolbarDialog,
            setSelected, createRoot, history)
        setCreateBlock(pre => ({...pre, loadingCreate: false}))
    }

    const handleSaveBlock = () => {
        handleElement('Save', 'zert-block', selectedItems, snackbar)
    }

    const handlePhrase = (valueEditor) => {
        setBlockCreate(pre => ({...pre, phraseEditor: valueEditor}))
    }


    useEffect(() => {
        createInitData(type, setSelectedItems)
    }, [type]);



    useEffect(() => {
        // typeSave, type, item, snackbar
        if(createRoot?.save && !createBlock.loadingInit){
            handleElement("Save", type, selectedItems.elementInfo, snackbar)
        }
    }, [createRoot?.save])



    useEffect(() => {
        if(blockCreate.item?.elementInfo?.name && !createRoot && type === "zert-image"){
            setImg(pre => ({...pre, blockItem: blockCreate.item}))
        }
    }, [blockCreate.item]);



    return (<div className={cl.wrapperBlockItems}>
        <div className={cl.container}>
            {selectedItems && <div style={{marginTop: 5}}>
                {selectedItems  && !createRoot && <div style={{marginBottom: 5}}>
                    <AbstractWrapper
                        id={selectedItems?.elementInfo?.versionId}
                        info={selectedItems?.elementInfo}
                        el={selectedItems}
                        context={BlockItemsContext}
                        saveOnlyItem={handleElement}
                        style={styleEditor}
                    />
                </div>}
                {type === "zert-phrase" && <>
                    <PhraseEditor
                        el={selectedItems}
                        vue={true}
                        setPhrase={handlePhrase}
                        context={BlockItemsContext}
                    />
                </>}
                {/*CreateImg*/}
                {type === "zert-image"  && <>

                    {!createRoot && <>
                        <div style={{marginTop: 10}}>
                            <TextFieldItem
                                values={img.blockItem?.elementInfo?.name}
                                name={"nameBlock"}
                                setValues={handleNameImg}
                                error={errorImg}
                                autoFocus={true}
                                style={{marginBottom: 5, "WebkitBoxShadow": errorImg ? "0 0 10px red" : "",}}
                            />
                        </div>
                        {blockCreate.item && <ImgTypeWrapper
                            setBlock={setImg}
                            el={blockCreate.item}
                            vue={!blockCreate.item}
                            context={BlockItemsContext}
                        />}
                    </>
                }</>}

            </div>}
            {(type === "zert-block"
                || (type === "zert-image"  && createRoot)
                    || type === "zert-index"
                    || type === "zert-softwaretexts"
                    || type === "zert-terminology-database"
                    || type === "zert-part") &&
                <div className={cl.wrapperTextField} style={{
                    gridTemplateColumns: createRoot ? "auto 1fr" : '1fr',
                }}>
                    {createRoot && <div className={cl.label}>Name</div>}
                    <div style={{"WebkitBoxShadow": error ? "0 0 10px red" : ""}}>
                        <TextFieldItem
                            values={name}
                            name={"nameBlock"}
                            setValues={handleName}
                            error={error}
                            autoFocus={true}
                        />
                    </div>
                </div>}
            {type === "zert-image"  && createRoot && <CreateImage createBlock={createBlock} setCreateBlock={setCreateBlock}/>}
            {type === "zert-part" && <CreatePart createBlock={createBlock} setCreateBlock={setCreateBlock} />}
            {type === "zert-softwaretexts" && <CreateSoftwareTexts createBlock={createBlock} setCreateBlock={setCreateBlock}/>}
            {type === "zert-table" && selectedItems && <>
                <CustomTable
                    table={selectedItems}
                    setTable={handleTable}
                    versionId={selectedItems.elementInfo.currentVersionId}
                    actionTypeProps={createBlock.actionTypeProps}
                    setName={setName}
                    createRoot={createRoot}
                    createBlock={createBlock}
                    setCreateBlock={setCreateBlock}
                />
            </>}
            {type === "zert-block" && <>
                {createRoot && <BlockFormat/>}
                {!createRoot && <div className={cl.addImgButton} onClick={handleSaveBlock}>
                    <AddIcon/>
                </div>}
            </>}
            {type === "zert-terminology_database" && <div>
                <div>Description</div>
                <TextFieldItem
                    values={createBlock.description}
                    name={"description"}
                    setValues={handleBlock}
                    multiline={true}
                    row={3}
                />
            </div>}
            {createRoot && <SwitchSetting/>}
        </div>
    </div>);
}

export default CreateItems