import {
    approveVersionApi,
    copyElementBlockApi, duplicateElementBlockApi,
    pasteElementBlockApi, saveCanvasItemPhrase, saveIndexApi,
    savePhraseApi,
    setTerm,
    storeBlockApi, tableStore
} from "../components/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AlertTextDialog from "../components/dialog/AlertTextDialog";
import BufferAlertDialog from "clm/src/plugins/BlockPlugin/components/dialog/BufferAlertDialog";
import {createInProgressSnack} from "../../common/snackBar";
import React from "react";
import {FormattedMessage} from "react-intl";
import { v4 as uuidv4 } from 'uuid';
import CreateDuplicateDialog from "../components/dialog/CreateDuplicateDialog";

export const saveStoreBlock = async (parentItemCreate, values, setValues, setBlock, newItems, items) => {
    //newItems
    const createItemData = {
        name: parentItemCreate.name,
        targetLocale: parentItemCreate.targetLocale,
        format: parentItemCreate.format,
        items
    };

    await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);

    if (setValues) {
        setValues(pre => ({
            ...pre,
            parentItem: parentItemCreate,
            blockClickCreateItemsBlock: false,
            // treeItem: newItems,
            investmentLevel: parentItemCreate.investmentLevel,
            action: {
                ...pre.action,
                saveElement: newItems,
                parentItemCreate: {...parentItemCreate, items, type: "save"}
            }
        }));
    }
    if (setBlock) {
        return setBlock(pre =>
            ({...pre, expandedToolbar: false, expandedToolbarBottom: false, createType: null,}));
    }
};

export const saveIndex = async (values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId) => {

    const name = block.nameBlock;
    const treeItem = {
        ...values.treeItem,
        elementInfo: {...values.treeItem.elementInfo, name},
        name
    };
    const newIndex = await saveIndexApi(treeItem.elementInfo.versionId, treeItem);

    if (!newIndex) {
        return console.log("error newPhrase", newIndex);
    }

    if (parentItemCreate) {
        const items = parentItemCreate.items.length > 0
            ? parentItemCreate.items.reduce((acu, cur, idx) => {
                if (idx === values?.action?.indexElementCreate) {
                    if (parentItemCreate.type === "create") {
                        if (block.expandedToolbarBottom) {
                            return [...acu, cur, newIndex,];
                        }
                        return [...acu, newIndex, cur];
                    }
                    return [...acu, newIndex];
                }
                return [...acu, cur];
            }, [])
            : [newIndex]

        await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newIndex, items);
    }

}

const findSavePhrase = (values, block) => {
    const treeItem = values.treeItem
    if (treeItem?.onlyTableSave || treeItem?.isSoftwareNewPhrase || treeItem.softWareText) {
        return treeItem
    }
    return block.blockItem;
}


export const savePhrase = async (values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId, targetLocale) => {
    // const treeItem = values.treeItem;
    const treeItem = findSavePhrase(values, block)
    const phraseEditor = block.phraseEditor;
    const adobeImg = Object.keys(phraseEditor.contentStateJSON.entityMap).map(key => {
        const item = phraseEditor.contentStateJSON.entityMap[key];
        return {
            ...item,
            data: {
                ...item.data,
                width: item.data.width === "100%" ? 0 : item.data.width,
                height: item.data.height === "100%" ? 0 : item.data.height,
                lengthUnit: item.data.lengthUnit ? item.data.lengthUnit : "PIXELS",
                verticalAlign: item.data.verticalAlign ? item.data.verticalAlign : "MIDDLE",
                verticalAlignDistance: item.data.verticalAlignDistance ? item.data.verticalAlignDistance : 0,
                verticalAlignUnit: item.data.verticalAlignUnit ? item.data.verticalAlignUnit : "PERCENT",
                keepRatio: item.data.keepRatio ? item.data.keepRatio : false
            }
        };
    });


    const data = {
        targetLanguage: targetLocale ? targetLocale : parentItemCreate?.targetLocale || treeItem.elementInfo.locale,
        parentVersion: versionId,
        elements: {blocks: phraseEditor.contentStateJSON.blocks, entityMap: {...adobeImg}},
        ...(phraseEditor.maxLength && {limitOfChars: phraseEditor.maxLength})
    };

    // return
    if (!treeItem && !values.createItem) {
        return;
    }

    const id = values?.createItem?.versionId || treeItem?.elementInfo?.versionId;


    const newPhrase = await savePhraseApi(id, data);


    if (!newPhrase) {
        return console.log("error newPhrase", newPhrase);
    }

    if (treeItem?.isSoftwareNewPhrase || treeItem?.softWareText) {

        //  treeItem.parentVersionId,
        const newPhraseSoftware = await setTerm(+treeItem.parentVersionId, id);
        if (newPhraseSoftware) {
            setValues(prev => ({
                ...prev, newPhrase: null,
                refresh: !prev.refresh
            }));
        }
    }

    if (parentItemCreate) {
        const items = parentItemCreate.items.length > 0
            ? parentItemCreate.items.reduce((acu, cur, idx) => {
                if (idx === values?.action?.indexElementCreate) {
                    if (parentItemCreate.type === "create") {
                        if (block.expandedToolbarBottom) {
                            return [...acu, cur, newPhrase,];
                        }
                        return [...acu, newPhrase, cur];
                    }
                    return [...acu, newPhrase];
                }
                return [...acu, cur];
            }, [])
            : [newPhrase]
        console.log('111', {parentItemCreate, items, newPhrase, values});
// return
        await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newPhrase, items);
    }
};


export const saveTableBlock = async (parentItemCreate, values, newTableItem, setValues, block, setBlock, myLanguage) => {
    const mylocale = myLanguage.value
    const newTable = await tableStore(newTableItem.elementInfo.versionId, mylocale, newTableItem)

    const items = parentItemCreate.items.length > 0
        ? parentItemCreate.items.reduce((acu, cur, idx) => {
            if (idx === values?.action?.indexElementCreate) {
                if (block.expandedToolbarBottom) {
                    return [...acu, cur, newTable,];
                }
                return [...acu, newTable, cur];
            }
            return [...acu, cur];
        }, [])
        : [newTable];
    await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newTableItem, items)
}

export const saveTable = async (values, block, mylocale, parentItemCreate, setValues, setBlock) => {
    const name = block.nameBlock;
    const adobeCreateTable = {
        ...block.table,
        elementInfo: {...block.table.elementInfo, name},
        name
    };

    const newTableItem = await tableStore(adobeCreateTable.elementInfo.versionId, mylocale, adobeCreateTable);

    if (!newTableItem) {
        return console.log("error newPhrase", newTableItem);
    }

    const items = parentItemCreate.items.length > 0
        ? parentItemCreate.items.reduce((acu, cur, idx) => {
            if (idx === values?.action?.indexElementCreate) {
                if (block.expandedToolbarBottom) {
                    return [...acu, cur, newTableItem,];
                }
                return [...acu, newTableItem, cur];
            }
            return [...acu, cur];
        }, [])
        : [newTableItem];

    await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newTableItem, items);
};


export const saveBlock = async (values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, zertImage) => {

    // if (!values.changes) {
    //   return;
    // }

    const items = {
        items: [],
        newItems: null
    };
    const createItemData = {
        name: parentItemCreate.name,
        targetLocale: parentItemCreate.targetLocale,
        format: parentItemCreate.format
    };


    if (parentItemCreate && parentItemCreate.type === "create") {

        const createBlockData = {
            ...values.treeItem,
            elementInfo: {...values.treeItem.elementInfo, name: block.nameBlock},
            name: block.nameBlock,
            format: parentItemCreate.format
        };

        const newBlock = await storeBlockApi(values.treeItem.elementInfo.versionId, values.treeItem.targetLocale.language, createBlockData);

        setValues(pre => ({...pre, actionTable: newBlock}));

        items.newItems = newBlock;
        items.items = parentItemCreate.items.length > 0
            ? parentItemCreate.items.reduce((acu, cur, idx) => {
                if (idx === values?.action?.indexElementCreate) {
                    if (block.expandedToolbarBottom) {
                        return [...acu, cur, newBlock,];
                    }
                    return [...acu, newBlock, cur];
                }
                return [...acu, cur];
            }, [])
            : [newBlock];

    } else {
        items.newItems = block.blockItem;
        items.items = parentItemCreate.items.reduce((acu, cur, idx) => {
            if (idx === values?.action?.indexElementCreate) {
                return [...acu, {...block.blockItem, elementInfo: {...cur.elementInfo, name: block.nameBlock}}];
            }
            return [...acu, cur];
        }, []);

        if (values.treeItem.elementInfo.mimeType.split("/").pop() === "zert-block") {
            //save active block
            const createBlockData = {
                ...values.treeItem,
                elementInfo: {...values.treeItem.elementInfo, name: block.nameBlock},
                name: block.nameBlock,
                format: parentItemCreate.format
            };
            await storeBlockApi(values.treeItem.elementInfo.versionId, mylocale, createBlockData);
        }
        //
    }


    if (zertImage && block.blockItem?.phraseCanvas?.elements?.length > 0) {
        await saveCanvasItemPhrase(
            block.blockItem.elementInfo.versionId,
            parentItemCreate.targetLocale.language,
            block.blockItem.phraseCanvas
        );
    }


    await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, {
        ...createItemData,
        items: items.items
    });


    if (setBlock) {
        setBlock(pre => ({
            ...pre,
            expandedToolbar: false,
            expandedToolbarBottom: false,
            createType: null
        }));
    }
    if (values.treeItem.typeAction && values.treeItem.typeAction === "only-save") {
        return setValues(pre => ({
            ...pre,
            action: {
                ...pre.action,
                saveElement: items.newItems,
                parentItemCreate: {...parentItemCreate, items: items.items, type: "save"}
            }
        }));
    }


    setValues(pre => ({
        ...pre,
        parentItem: parentItemCreate,
        blockClickCreateItemsBlock: false,
        // treeItem: items.newItems,
        investmentLevel: parentItemCreate.investmentLevel,
        action: {
            saveElement: items.newItems,
            parentItemCreate: {...parentItemCreate, items: items.items, type: "save"}
        },
    }));
};

export const cancelBlock = (values, setValues, item) => {
    const res = {};
    if (values.beforeTreeItem) {
        res.beforeTreeItem = values.beforeTreeItem.slice(0, -1);
    }
    setValues(pre => ({
            ...pre,
            closeChild: item?.identifier,
            blockClickCreateItemsBlock: false,
            ...res
            // action: {...pre.action, cancel: true}
        }
    ));
};


export const removeElement = async (generatingSnackbar, parentItemCreate, values, setValues, snackbar, request) => {
    const createItemData = {};
    if (parentItemCreate) {
        const items = parentItemCreate.items.filter((el, i) => i !== values?.action?.indexElementCreate);
        createItemData.name = parentItemCreate.name;
        createItemData.targetLocale = parentItemCreate.targetLocale;
        createItemData.format = parentItemCreate.format;
        createItemData.items = items;
    }
    const remove = async () => {
        await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);
        if (setValues) {
            setValues(pre => ({
                ...pre,
                treeItem: null,
                closeChild: false,
                dropBlockTable: false,
                investmentLevel: 0,
                action: {
                    ...pre.action,
                    parentItemCreate: {...parentItemCreate, items: createItemData.items, type: "remove"}
                }
            }));
        }
        snackbar.closeSnackbar(generatingSnackbar);
    };

    const cancellation = () => {
        snackbar.closeSnackbar(generatingSnackbar);
    };
    console.log('!request', !request)
    if(!request){
        return remove()
    }
    console.log('----------')
    renderInExceptionRoot(AlertTextDialog, {"setConfirm": remove, "setClose": cancellation, "title": "Information"});
};

export const handleItemsSave = (elementInfo, item, parentItemCreate, setValues) => {
    const items = parentItemCreate.items.reduce((acu, cur) => {
        if (cur.elementInfo.versionId === item.versionId) {
            const result = {...cur, elementInfo}
            return [...acu, result]
        }
        return [...acu, cur]
    }, [])
    setValues(pre => ({
        ...pre,
        action: {
            ...pre.action,
            parentItemCreate: {...parentItemCreate, items, type: "save"}
        },
    }));
}


export const approveElement = async (generatingSnackbar, snackbar, item, addApproveTable, setValues, parentItemCreate) => {
    const approve = async () => {
        const res = await approveVersionApi(item.companyId, item.versionId);
        if (res && setValues && parentItemCreate) {
            handleItemsSave(res, item, parentItemCreate, setValues)
        }
        if (addApproveTable) {
            addApproveTable()
        }
        snackbar.closeSnackbar(generatingSnackbar);
    };
    const cancellation = () => {
        snackbar.closeSnackbar(generatingSnackbar);
    };
    renderInExceptionRoot(AlertTextDialog, {"setConfirm": approve, "setClose": cancellation, "title": "Approve ?"});
};

export const copyElementBlock = async (values) => {
    await copyElementBlockApi(JSON.stringify(values.treeItem));
    // localStorage.setItem("copyBlockItems", JSON.stringify({
    //   ...values.treeItem,
    //   parentItem: selectedRange.parentSelectedLvl || values.parentItem,
    //   investmentLevelBlock: values.investmentLevel
    // }));
};



export const duplicateItemBlock = async (snackbar, values, type, mylocale,block, parentItemCreate, setValues,  setBlock) => {


    const saveDuplicateElementBlock = async (novBlock, name) => {
        const versionId = values.treeItem?.elementInfo.versionId
        const generatingSnackbar =
            createInProgressSnack(
                snackbar,
                <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Duplicate" defaultMessage="Duplicate..."/>
            );


        const data = novBlock ? '{}' : {name}
        const res = await duplicateElementBlockApi(versionId,true, data);

        if(!res || !values?.treeItem){
            return  snackbar.closeSnackbar(generatingSnackbar)
        }

        const adobeElement = {
           ...values.treeItem,
            elementInfo : {...res,},
            identifier: `zert:Item[${uuidv4()}]`,
        }

        if(!novBlock){
            adobeElement.elementInfo.name = name
        }

        const items = parentItemCreate.items.reduce((acu, cur, ) => {
            if (cur.elementInfo.versionId === values.treeItem.elementInfo.versionId ) {
                return [...acu, cur, adobeElement];
            }
            return [...acu, cur];
        }, []);

        const createItemData = {
            name: parentItemCreate.name,
            targetLocale: parentItemCreate.targetLocale,
            format: parentItemCreate.format,
            items
        };

        await storeBlockApi(parentItemCreate.elementInfo.versionId, mylocale, createItemData);

        setBlock(pre => ({
            ...pre,
            expandedToolbar: false,
            expandedToolbarBottom: false,
            createType: null,
        }));

        setValues(pre => ({ ...pre,
            treeItem:   adobeElement,
            action: {
                ...pre.action,
                saveElement: items.newItems,
                parentItemCreate: {...parentItemCreate, items, type: "save"}
            }
        }));
        snackbar.closeSnackbar(generatingSnackbar)
    }

    if(type === 'zert-block'){
        renderInExceptionRoot( CreateDuplicateDialog, {values,
            handleSave: (val) => saveDuplicateElementBlock(false, val)})
    } else {
        saveDuplicateElementBlock(true)
    }

}

const pastCollapseBlock = async (item, snackbar, values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, generatingSnackbar) => {

    const items = parentItemCreate.items.reduce((acu, cur, idx) => {
        if (idx === values?.action?.indexElementCreate) {
                return [...acu, cur, item,];
        }
        return [...acu, cur];
    }, [])
    setValues(pre => ({
        ...pre,
        parentItem: parentItemCreate,
        blockClickCreateItemsBlock: false,
        treeItem: item,
        investmentLevel: parentItemCreate.investmentLevel,
        action: {
            saveElement: item,
            parentItemCreate: {...parentItemCreate, items, type: "save"}
        },
    }));

    const createBlockData = {
        ...parentItemCreate,
        items,
    };

    await storeBlockApi(parentItemCreate.elementInfo.versionId, mylocale, createBlockData);
    snackbar.closeSnackbar(generatingSnackbar);
}


export const pastElementBlock = async (snackbar, values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, type) => {

    const res = await pasteElementBlockApi();

    if (!res.elementInfo) {
        return await renderInExceptionRoot(BufferAlertDialog, {});
    }
    const elementSave = block.child
    if(elementSave && (elementSave.elementInfo?.id === res.elementInfo.id && !block.colapse) ){
        const text = 'An element tried to refrence itself, this is not allowed, operation aborted'
        return renderInExceptionRoot(AlertTextDialog, { text, cancel: false });

    }
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
    );

    const adobeRes = {...res, identifier: `zert:Item[${uuidv4()}]`}


    if(block.colapse || type !== 'zert-block'){
        return  pastCollapseBlock(adobeRes, snackbar, values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, generatingSnackbar, type !== 'zert-block')
    }

    // console.log('1', {res, parentItemCreate, block})
    // return

    const createItemData = {
        name: elementSave.name,
        targetLocale: elementSave.targetLocale,
        format: elementSave.format
    };
    const items = [...elementSave.items, adobeRes]


    await storeBlockApi(elementSave.elementInfo.versionId, elementSave.targetLocale.language, {
        ...createItemData,
        items,
    });
    setBlock(pre => ({
        ...pre,
        expandedToolbar: false,
        expandedToolbarBottom: false,
        createType: null,
        child : {...pre.child, items}
    }));
    setValues(pre => ({...pre, treeItem: adobeRes, investmentLevel: parentItemCreate.investmentLevel + 1,}));
    snackbar.closeSnackbar(generatingSnackbar);
};

export const duplicateElementBlock = async (values,) => {
    const item = values.treeItem?.elementInfo;
   return  await duplicateElementBlockApi(item.versionId, true);
};


export const addLabel = async (val, parentItemCreate, values, mylocale, block) => {
    const createBlockData = {
        ...values.treeItem,
        elementInfo: {...values.treeItem.elementInfo},
        label: val,
        targetLocale: parentItemCreate.targetLocale,
        format: parentItemCreate.format,
    };

     await storeBlockApi(values.treeItem.elementInfo.versionId, mylocale, createBlockData);
}

export const addAlias = (val) => {

};

export const saveImg = (e) => {

};

export const handleUngroup = async (snackbar, values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, type) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
    );
    snackbar.closeSnackbar(generatingSnackbar);
    const child = block?.child?.items

    const items = parentItemCreate.items.reduce((acu, cur, idx) => {
        if (idx === values?.action?.indexElementCreate) {
            if(child){
                return [...acu, ...child];
            }
            return acu
        }
        return [...acu, cur];
    }, [])

    const item = child ? child[child.length - 1] : null

 
    setValues(pre => ({
        ...pre,
        parentItem: parentItemCreate,
        blockClickCreateItemsBlock: false,
        treeItem: item,
        investmentLevel: parentItemCreate.investmentLevel,
        action: {
            saveElement: item,
            parentItemCreate: {...parentItemCreate, items, type: "save"}
        },
    }));
    //
    const createBlockData = {
        ...parentItemCreate,
        items,
    };

    await storeBlockApi(parentItemCreate.elementInfo.versionId, mylocale, createBlockData);
     snackbar.closeSnackbar(generatingSnackbar);
}