import React, { useContext, useState } from "react";
import { IconButton } from "@mui/material";
import { RichUtils, EditorState } from "draft-js";
import { makeStyles } from "@mui/styles";


import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import { PhraseContext } from "../PhraseEditor";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";

const useStyles = makeStyles((theme) => ({
  buttonRoot: (props) => ({
    color: props.color,
    background: props.background,
    padding: 5,
    "& svg": {
      fontSize: "1.25rem"
    },
    "&:hover": {
      color: "#ffffff !important",
      background: "#01A1C7 !important"
    }
  })
}));

const TextFormats = ({ type = "" }) => {

  const { values, setValues } = useContext(PhraseContext);
  const parentContext = useContext(PanelContext)
  const currentStyle = values.editorState.getCurrentInlineStyle();
  const selected = currentStyle.has(type);
  const cl = useStyles({
    color: selected ? "#ffffff !important" : "#555555 !important",
    background: selected ? "#01A1C7 !important" : "none !important"
  });



  const handleStyle = (e) => {
    e.preventDefault()
    if(parentContext?.setValues){
      parentContext.setValues(pre => ({ ...pre, changeBlock: true }));
    }
    const newState = RichUtils.toggleInlineStyle(values.editorState, type);
    setValues(pre => ({ ...pre, editorState: newState }));
  };

  return (
    <div>
      <IconButton
        className={cl.buttonRoot}
        onClick={(e) => handleStyle(e)}
        onMouseDown={(e) => e.preventDefault()}
      >
        {type === "BOLD" && <FormatBoldIcon />}
        {type === "ITALIC" && <FormatItalicIcon />}
      </IconButton>

    </div>

  );
};

export default TextFormats;