import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import useSelectionModel from '@zert-packages/components/EnhancedTable/useSelectionModel';
import { getPluginByMimeType, getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import { getProductActions } from '@zert-packages/plugins/Environment';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import EnhancedTable from '../EnhancedTable/EnhancedTable';
import { getTileElementStatus, getTileElementStatusMessage, getTileStatus } from './utils/getTileStatus';
import LabelRM from '../shared/Label';
import GeneralTab from '../InfoTabs/GeneralTab';
import ParentTree from '../InfoTabs/ParentTree';
import TranslationStatusTree from '../InfoTabs/TranslationStatusTree';
import useElementActions from './useElementActions';
import getOverviewByMimeType from './utils/getOverviewByMimeType';
import useExpandByMimeType from './utils/useExpandByMimeType';
import { useElementSorter } from './utils/useElementSorter';
import { useElementOpen } from './utils/useElementOpen';

const tabsListNew = [
  {
    label: <FormattedMessage id="InfoTab.TabGeneral" defaultMessage="General" />
  },
  {
    label: <FormattedMessage id="InfoTab.Tree" defaultMessage="Tree" />
  },
  {
    label: <FormattedMessage id="InfoTab.TranslationStatus" defaultMessage="Translation status" />
  }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '50%',
    display: 'flex',
    overflow: 'auto',
    flexFlow: 'column nowrap'
  },
  rootTab: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridGap: 6,
    paddingTop: 2,
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
});

export const additionalColumns = [
  {
    identifier: 'creator',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Creator" defaultMessage="Created by" />
  }
];

export const columns = [
  {
    identifier: 'nameNew',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'mimeTypeNew',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Type" defaultMessage="Type" />
  },
  {
    identifier: 'owner',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Owner" defaultMessage="Owner" />
  },
  {
    identifier: 'date',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Date" defaultMessage="Date" />
  },
  {
    identifier: 'label',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Version" defaultMessage="Version" />
  },
  {
    identifier: 'lastCompletedDate',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.LastCompleted" defaultMessage="Last completed" />
  },
  {
    identifier: 'status',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Status" defaultMessage="Status" />
  },
  {
    identifier: 'id',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Id" defaultMessage="Id" />
  }
  /*   { identifier: 'published', numeric: false, disablePadding: false, isFilterable : true, align : "left", label: <FormattedMessage id = "Element.TileTable.Published" defaultMessage = "Published"/> },
   */
];

function DetailsTileTable({ selectedElement }) {
  const { formatTemplate } = useSelector((state) => state);
  const [tabIndex, setTabIndex] = useState(0);
  const cl = useStyles();
  const { expand } = useExpandByMimeType({ element: selectedElement });
  const component = getOverviewByMimeType({ element: selectedElement });

  useEffect(() => {
    expand(selectedElement);
  }, []);

  return (
    <>
      {formatTemplate && (
        <div className={cl.rootTab}>
          <Tabs
            value={tabIndex}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            onChange={(e, value) => setTabIndex(value)}
          >
            {tabsListNew.map((tab, index) => (
              <Tab {...tab} key={index} />
            ))}
            {component && (
              <Tab
                label={<FormattedMessage id="InfoTab.Overview" defaultMessage="Overview" />}
                key={tabsListNew.length}
              />
            )}
          </Tabs>

          <div className={cl.contentContainer}>
            {tabIndex === 0 && <GeneralTab info={selectedElement} />}
            {tabIndex === 1 && <ParentTree info={selectedElement} />}
            {tabIndex === 2 && <TranslationStatusTree info={selectedElement} />}
            {tabIndex === 3 && component}
          </div>
        </div>
      )}
      {!formatTemplate && component && (
        <div className={cl.rootTab}>
          <div className={cl.contentContainer}>{component}</div>
        </div>
      )}
    </>
  );
}

function ElementTileTable({
  extendHandler,
  columnsNew,
  elements,
  maxHeight,
  loading,
  bradCrumbComponent,
  showCut,
  showFileUpload,
  showElementActions = false,
  doNotShowSelect = false,
  doNotShowToolbar = false,
  customFilter,
  intl,
  reloadElement,
  otherActions,
  pagination,
  orderBy,
  order,
  productSpecificActions,setActiveElement,
  hideToolBar
}) {
  const myUser = useSelector((state) => state.myuser);

  const { open } = useElementOpen();
  const { getSorter } = useElementSorter(intl);

  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'nameNew':
        return element.name;
      case 'deadline':
        if (element.properties == null) return null;
        return element.properties['zert:CurrentActivityDeadline'];
      case 'mimeTypeNew':
        const plugin = getPluginByMimeType(element.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.mimeType;
      case 'status':
        return getTileElementStatusMessage(element, myUser, intl);
      default:
        return null;
    }
  };


  const handler = () => {
    const _handler = {
      get(target, key) {
        if (key === 'nameNew') {
          return (
            <label style={{ display: 'flex', alignItems: 'center', margin: 'unset', cursor: 'pointer' }}>
              <div className="icon-box" style={{ padding: '0px 5px 0 0', color: '#1BA1C5' }}>
                {target.mimeType && <BlockIcon svg={getPluginByMimeTypeIcon(target.mimeType, true)} color="#1BA1C5" />}
              </div>
              {target.name}
            </label>
          );
        } else if (key === 'owner') {
          return target.changedBy ? target.changedBy : target.createdBy;
        } else if (key === 'csize') {
          return target.properties ? target.properties.csize : null;
        } else if (key === 'deadline') {
          return target.properties && target.properties['zert:CurrentActivityDeadline']
            ? target.properties['zert:CurrentActivityDeadline'].split(' ')[0]
            : undefined;
        } else if (key === 'mimeTypeNew') {
          return getPluginByMimeType(target.mimeType).name;
        } else if (key === 'date') {
          return target.changedAt ? target.changedAt.split(' ')[0] : target.createdAt.split(' ')[0];
        } else if (key === 'lastCompletedDate') {
          return target.latestApprovedChecklistDateForSafetyInspection ? target.latestApprovedChecklistDateForSafetyInspection.split(' ')[0] : '-';
        } else if (key === 'status') {
          return (
            <LabelRM text={getTileStatus(target, myUser)} styleName={`${getTileElementStatus(target, myUser)} plain`} />
          );
        } else if (key === 'creator') {
          return target.createdBy;
        } else if (typeof target[key] === 'object' && target[key] !== null) {
          return new Proxy(target[key], _handler);
        } else if (extendHandler) {
          return extendHandler(target, key);
        }
        return target[key];
      }
    };
    return _handler;
  };

  const columnHandler = () => {
    const _handler = {
      get(target, key) {
        if (key === 'customComparator') {
          return getSorter(target.identifier);
        }
        /* if (key === 'customClickHandler' && target.identifier === 'nameNew') {
          return (row, column, event) => {
            open(row, column, event);
          };
        } */
        if (key === 'customClickHandler' && target.identifier !== 'nameNew') {
          return null;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const model = elements ? elements.map((element) => new Proxy(element, handler(element))) : [];
  const columnsG = columnsNew || columns;
  const columnModel = columnsG ? columnsG.map((column) => new Proxy(column, columnHandler(column))) : [];
  const selectionModel = useSelectionModel(model, false, 'versionId');
  const _customFilter = customFilter || getCustomFilter;




  return (
    <EnhancedTable
      rows={model}
      identifier="versionId"
      columns={columnModel}
      detailComponent={DetailsTileTable}
      maxHeight={maxHeight}
      actions={useElementActions}
      loading={loading}
      getCustomFilter={_customFilter}
      bradCrumbComponent={bradCrumbComponent}
      showCut={showCut}
      showFileUpload={showFileUpload}
      showElementActions={showElementActions}
      doNotShowSelect={doNotShowSelect}
      doNotShowToolbar={doNotShowToolbar}
      selectionModel={selectionModel}
      reloadElement={reloadElement}
      otherActions={otherActions}
      pagination={pagination}
      sortingKey={orderBy}
      productSpecificActions={productSpecificActions}
      sortingOrder={order}
      setActiveElement={setActiveElement}
      hideToolBar={hideToolBar}
    />
  );
}

export default injectIntl(
  memo(
    ({
      elements,
      maxHeight,
      loading,
      bradCrumbComponent,
      showCut,
      showFileUpload,
      showElementActions,
      doNotShowSelect,
      doNotShowToolbar,
      customFilter,
      intl,
      reloadElement,
      otherActions,
      pagination,
      orderBy,
      order,
      columnsNew,
      extendHandler,setActiveElement,
      hideToolBar,
    }) => (
      <ElementTileTable
        bradCrumbComponent={bradCrumbComponent}
        loading={loading}
        showCut={showCut}
        showFileUpload={showFileUpload}
        showElementActions={showElementActions}
        doNotShowSelect={doNotShowSelect}
        doNotShowToolbar={doNotShowToolbar}
        customFilter={customFilter}
        intl={intl}
        otherActions={otherActions}
        reloadElement={reloadElement}
        elements={elements}
        maxHeight={maxHeight}
        pagination={pagination}
        orderBy={orderBy}
        columnsNew={columnsNew}
        productSpecificActions={getProductActions()}
        extendHandler={extendHandler}
        order={order}
        setActiveElement={setActiveElement}
        hideToolBar={hideToolBar}
      />
    ),

    selectiveMemoComparison(
      (p) => p.elements,
      (a) => a.loading,
      (c) => c.maxHeight
    )
  )
);
