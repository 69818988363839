export const findActiveLocales = (versionInfo, activeLocales) => {
  if(!versionInfo.info.translatable){
    return activeLocales
  }
  const res = activeLocales.filter(el => versionInfo.versions.find(it => it.locale === el.value))
  if(res.length > 0){
    return  res
  }
  return activeLocales
}

export const adobeTabs = (width) => {


  if(width < 310){
    return  -5
  }

  if(width < 400){
    return  -7
  }

  if(width < 450){
    return  -6
  }

  if(width < 510){
    return  -4
  }

  if(width < 600){
    return  -3
  }

  if(width < 635){
    return  -1
  }

  if(width < 650){
    return  100
  }

  if(width < 850){
    return  100
  }

  if(width < 901){
    return  100
    // return  -2
  }

  if(width < 949){
    // return  -1
    return  100
  }

  if(width > 949){
    return  100
  }

}