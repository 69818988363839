import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Menu, MenuItem, Fade } from "@mui/material";
import { RichUtils } from "draft-js";
import { PhraseContext } from "../PhraseEditor";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";


const useStyles = makeStyles((theme) => ({
  menu: {
    color: "#919191 !important",
    border: "1px solid grey !important",
    position: "relative",
    zIndex: 1000,
    paddingTop: 3,
    paddingBottom: 3,
    width: "100%",
    maxWidth: "fit-content",
    textTransform: "none",
    "&:hover": {
      color: "#ffffff !important",
      background: "#01A1C7 !important",
      border: "1px solid #01A1C7 !important",
      "& svg": {
        color: "#ffffff !important"
      }
    },
    "&:focus": {
      outline: "none"
    }
  },
  button: {
    border: '1px solid grey',
    color: 'grey'
  },
  menuItem: {
    zIndex: 99999,
    position: 'relative',
    // color:  props.color,
    // background: props.background,
    minWidth: "max-content",
    "& svg": {
      fontSize: "1.25rem"
    },
    "&:hover": {
      color: "#ffffff !important",
      background: "#01A1C7 !important"
    }
  },
  titleImg: {
    color: "#212529",
    marginLeft: 5
  },
  closeBlock:{
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
    zIndex: 1000
  },
  wrapperList: {
    position: "absolute",
    zIndex: 9999,
    marginTop: '5px'
  },
}));

const DropMenuStyle = ({ label = "", dropMenu, handle }) => {
  const cl = useStyles();
  const { values, setValues } = useContext(PhraseContext);
  const parentContext = useContext(PanelContext)
  const currentStyle = values.editorState.getCurrentInlineStyle();
  const [open, setOpen] = useState(null);
  const buttonRef = useRef(null);

  const handleStyle = (e, el) => {

    if(parentContext?.setValues){
      parentContext.setValues(pre => ({ ...pre, changeBlock: true }));
    }

    if (handle) {
      handle(el.type);
      return setOpen(false);
    }
    const newState = RichUtils.toggleInlineStyle(values.editorState, el.style.toUpperCase());
    setValues(pre => ({ ...pre, editorState: newState, changeBlock: true }));
    setOpen(false);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };



  return (<div>
    <Button
      ref={buttonRef}
      onClick={handleClick}
      onMouseDown={(e) => e.preventDefault()}
      className={cl.button}
    >
      {label} <MenuIcon className={cl.titleImg} />
    </Button>

    {open && <>
      <div className={cl.closeBlock}  onClick={handleClose}></div>
      <Paper elevation={3} className={cl.wrapperList}>
      {dropMenu && dropMenu.map((el, i) =>
        <MenuItem
          style={{
            width: "100%",
            color: !handle && currentStyle.has(el.style.toUpperCase()) ? "#ffffff" : "#555555",
            background: !handle && currentStyle.has(el.style.toUpperCase()) ? "#01A1C7" : "none"
          }}
          className={cl.menuItem} key={i} onClick={(e) => handleStyle(e, el)}>
          {el.name}
        </MenuItem>)}
    </Paper></>}
  </div>);
};

export default DropMenuStyle;

