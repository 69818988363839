import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import React from 'react';
import getLang from '@zert-packages/utils/getLang';
import { FormattedMessage } from 'react-intl';
import { addElementsToFolderApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import { createInProgressSnack, createOpenReportSnack } from '@zert-packages/components/common/snackBar';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';

import { placeElementNew } from './catalogActions';
import {
  fetchElementCreate,
  fetchElementCreated,
  fetchElementStored,
  fetchElementStoreStart,
  fetchRA_Error,
  fetchRA_Rescent_Begin,
  fetchRA_Rescent_Success,
  handlePost,
  handlePostFile,
  handlePostTextAsParam, handlePostToApi,
  handleQuery,
  updateEmptyRiskRow
} from "./api";
import {
  ADD_RISKROW,
  ADD_RISKROW_BEGIN,
  ADD_RISKROW_NODE,
  ADD_RISKROW_POS,
  ADD_RISKROW_SUCCESS,
  FETCH_ADD_MEASURE,
  FETCH_ADD_MEASURE_DONE,
  FETCH_ADD_MEASURE_FINISH,
  FETCH_MEASURE_TEMPLATE_RA_SUCCESS,
  FETCH_RA_BEGIN,
  FETCH_RA_IDENTIFIER_DONE,
  FETCH_RA_LOADED,
  FETCH_RA_OVERVIEW_BEGIN,
  FETCH_RA_OVERVIEW_DONE,
  FETCH_RA_OVERVIEW_SUCCESS,
  FETCH_RA_SUCCESS,
  FETCH_RISKROW_TEMPLATE_RA_SUCCESS,
  FETCH_RISKROW_TEMPLATES_START,
  FETCH_RISKROW_TEMPLATES_SUCCESS,
  FETCH_WF_RA_SUCCESS,
  FOCUS_RISKROW,
  RA_ACTIONS_DISABLED,
  RA_ACTIONS_ENABLED,
  RA_ADD_NODE,
  RA_ADD_NODE_DONE,
  RA_ADD_NODE_UUID,
  RA_COVER_UPLOAD_BASIS,
  RA_COVER_UPLOAD_BEGIN,
  RA_COVER_UPLOAD_SUCCESS,
  RA_UPLOAD_BASIS,
  RA_UPLOAD_BASIS_BEGIN,
  RA_UPLOAD_BASIS_CLEAR,
  RA_UPLOAD_BASIS_SUCCESS
} from './actions';

export const disableRAActions = () => ({ type: RA_ACTIONS_DISABLED });
export const enableRAActions = () => ({ type: RA_ACTIONS_ENABLED });

export const fetchRA_Begin = () => ({
  type: FETCH_RA_BEGIN
});

export const fetchRA_Success = (riskassesment) => ({
  type: FETCH_RA_SUCCESS,
  payload: { riskassesment }
});

export const fetchRA_Template_Success = (template) => ({
  type: FETCH_RISKROW_TEMPLATE_RA_SUCCESS,
  payload: { template }
});
export const fetchRA_overview_Success = (raoverview) => ({
  type: FETCH_RA_OVERVIEW_SUCCESS,
  payload: { raoverview }
});
export const fetch_RA_overview_BEGIN = (versiontid) => ({
  type: FETCH_RA_OVERVIEW_BEGIN,
  payload: { versiontid }
});
export const fetch_RA_overview_DONE = () => ({
  type: FETCH_RA_OVERVIEW_DONE,
  payload: {}
});

export const fetchRA_WorkFlowTemplate_Success = (workflowtemplate) => ({
  type: FETCH_WF_RA_SUCCESS,
  payload: { workflowtemplate }
});

export const fetchRISK_ROWS_TEMPLATES_SUCCESS = (templates) => ({
  type: FETCH_RISKROW_TEMPLATES_SUCCESS,
  payload: { templates }
});
export const fetchRISK_ROWS_TEMPLATES_BEGIN = () => ({
  type: FETCH_RISKROW_TEMPLATES_START,
  payload: {}
});

export const fetchRA_MeasureTemplate_Success = (measuretemplate) => ({
  type: FETCH_MEASURE_TEMPLATE_RA_SUCCESS,
  payload: { measuretemplate }
});

export const fetchRA_Identifier_Success = (identifier) => ({
  type: FETCH_RA_IDENTIFIER_DONE,
  payload: { identifier }
});

export const fetchRA_Loaded = () => ({
  type: FETCH_RA_LOADED
});
export const fetchAddMeasureStart = () => ({
  type: FETCH_ADD_MEASURE
});
export const fetchAddMeasureDone = (measure, riskid) => ({
  type: FETCH_ADD_MEASURE_DONE,
  payload: {
    measure,
    riskid
  }
});
export const fetchAddMeasureFinish = () => ({
  type: FETCH_ADD_MEASURE_FINISH
});

export const fetchRABasisUpload_Begin = (idForm) => ({
  type: RA_UPLOAD_BASIS_BEGIN,
  payload: { idForm }
});

export const fetchRABasisUpload = (basis) => ({
  type: RA_UPLOAD_BASIS,
  payload: { basis }
});

export const fetchRABasisUpload_DONE = () => ({
  type: RA_UPLOAD_BASIS_SUCCESS,
  payload: {}
});

export const fetchRABasisUploadClear = () => ({
  type: RA_UPLOAD_BASIS_CLEAR,
  payload: {}
});

export const putADD_RISKROW_BEGIN = () => ({
  type: ADD_RISKROW_BEGIN
});

export const putADD_RISKROW = (riskrow) => ({
  type: ADD_RISKROW,
  payload: { riskrow }
});

export const putADD_RISKROW_POS = (posid) => ({
  type: ADD_RISKROW_POS,
  payload: { posid }
});

export const putADD_RISKROW_NODE = (nodeuuid) => ({
  type: ADD_RISKROW_NODE,
  payload: { nodeuuid }
});

export const putADD_RISKROW_SUCCESS = () => ({
  type: ADD_RISKROW_SUCCESS,
  payload: {}
});

const fetchAddNewSection = (ra_node, uuid, sibling) => ({
  type: RA_ADD_NODE,
  payload: {
    ra_node,
    uuid,
    sibling
  }
});

const fetchAddNewSectionDone = () => ({
  type: RA_ADD_NODE_DONE,
  payload: {}
});

const fetchAddNewSectionUUID = (uuid) => ({
  type: RA_ADD_NODE_UUID,
  payload: { uuid }
});

const fetchCoverUpload_Begin = () => ({
  type: RA_COVER_UPLOAD_BEGIN
});

const fetchCoverUpload = (basis) => ({
  type: RA_COVER_UPLOAD_BASIS,
  payload: { basis }
});

const fetchCoverUpload_DONE = () => ({
  type: RA_COVER_UPLOAD_SUCCESS,
  payload: {}
});

export const createNewRowVersion = (raVersionId, rowVersionId, nodeUUID) => (dispatch, getState, hooks) => {
  dispatch(disableRAActions());

  const RA = { ...getState().riskassesment };

  const updateRiskRow = (rootNode, newRiskRow) => {
    const cachedRiskRows = rootNode.cachedRiskRows.map((row) => {
      if (row.status.versionId === rowVersionId) return newRiskRow;
      return row;
    });

    const children = rootNode.children.map((child) => updateRiskRow(child, newRiskRow));
    return {
      ...rootNode,
      cachedRiskRows,
      children
    };
  };

  handleQuery(`/riskanalises/createNewVersion/${rowVersionId}/${raVersionId}/${nodeUUID}`).then((res) => {
    dispatch(enableRAActions());
    RA.rootNode = updateRiskRow(RA.rootNode, res);
    dispatch(fetchRA_Success(RA));
    showSnackbarChangesSaved(hooks.snackbar);
  });
};

export const focusElement = (riskrow) => ({
  type: FOCUS_RISKROW,
  payload: { riskrow }
});

export const createNewRowVersionAsync = async ({ rowVersionId, raVersionId, uuid, dispatch, snackbar }) => {
  dispatch(disableRAActions());
  try {
    const newRiskRow = await handleQuery(`/riskanalises/createNewVersion/${rowVersionId}/${raVersionId}/${uuid}`);
    newRiskRow.expanded = true;

    dispatch(enableRAActions());
    if (snackbar) {
      showSnackbarChangesSaved(snackbar);
    }
    dispatch(focusElement(newRiskRow));
    dispatch(enableRAActions());
    return newRiskRow;
  } catch (e) {
    dispatch(enableRAActions());
    showSnackbarError(snackbar, e.message);
    dispatch(enableRAActions());
    return null;
  }
};
export const addNewRiskRowAsync = async (versionId, nodeuuid, pos, dispatch, snackbar) => {
  dispatch(disableRAActions());
  try {
    const newRiskRow = await handleQuery(
      `/riskanalises/addNewRiskRow/${versionId}/${nodeuuid.replace('[', '').replace(']', '')}/${pos}`
    );
    newRiskRow.expanded = true;
    // riskRows.splice(pos, 0, newRiskRow);
    if (snackbar) {
      showSnackbarChangesSaved(snackbar);
    }
    dispatch(focusElement(newRiskRow));
    dispatch(enableRAActions());
    return newRiskRow;
  } catch (e) {
    showSnackbarError(snackbar, e.message);
    dispatch(enableRAActions());
  }
};

export function addNewRiskRow(versionId, nodeuuid, pos) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(
      `/riskanalises/addNewRiskRow/${versionId}/${nodeuuid.replace('[', '').replace(']', '').replace(',', '-')}/${pos}`
    )
      .then((riskrow) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(enableRAActions());
        dispatch(putADD_RISKROW(riskrow));
        dispatch(putADD_RISKROW_NODE(nodeuuid));
        dispatch(putADD_RISKROW_POS(pos));

        dispatch(putADD_RISKROW_SUCCESS());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(putADD_RISKROW_BEGIN());
  };
}

export async function initRiskRowTemplate(name) {
  const promise = await handlePost('/riskrowtemplate/initRiskRowTemplate', name);
  return promise;
}

export async function initMeasureTemplate(name) {
  const promise = await handlePost('/measure/initMeasureTemplate', name);
  return promise;
}

export async function initWorkflow(name) {
  const promise = await handlePost('/riskrowtemplate/initWorkflow', name);
  return promise;
}

export async function initMatrix(name) {
  const promise = await handlePost('/riskrowtemplate/initMatrix', name);
  return promise;
}

export function fetchAssessmentRiskRow() {
  return handleQuery('/riskrowtemplate/riskRowTemplates');
}

export function searchElements(showPast, search) {
  return function (dispatch) {
    handlePostTextAsParam(`/riskanalises/search/${showPast}`, search)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function loadRescentElements(showCurrent, showPast) {
  return function (dispatch) {
    handleQuery(`/riskanalises/rescent/${showCurrent}/${showPast}`)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function uploadBasisRA(filedata, versionId, closeLoader) {
  return function (dispatch) {
    handlePostFile(`/riskanalises/uploadFile/${versionId}`, filedata)
      .then((basis) => {
        if (typeof closeLoader === 'function') closeLoader();
        dispatch(fetchRABasisUpload(basis));
        dispatch(fetchRABasisUpload_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRABasisUpload_Begin(null));
  };
}

export function storeRiskRowWorkflow(versionId, data, ownerId = -1) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePost(`/riskanalises/storeRiskRow/${ownerId}/${versionId}`, data)
      .then((res) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(enableRAActions());
        dispatch(fetchElementStored());
      })
      .catch((error) => console.log('Save not succesefull. Think what to do'));
    return dispatch(fetchElementStoreStart());
  };
}

const createAddRiskRow = (nodeUUID) =>
  function addRiskRow(rootNode, newRiskRow) {
    let cachedRiskRows = [];
    if (rootNode.uuid === nodeUUID) {
      cachedRiskRows = [...rootNode.cachedRiskRows, newRiskRow];
    } else {
      cachedRiskRows = rootNode.cachedRiskRows;
    }

    const children = rootNode.children.map((child) => addRiskRow(child, newRiskRow));
    return {
      ...rootNode,
      cachedRiskRows,
      children
    };
  };
export const addNewMeasureAsync = async (riskRow, dispatch, ownerId, nodeUUID, snackbar) => {
  dispatch(disableRAActions());
  try {
    let { versionId } = riskRow.status;
    riskRow.status.identifier = nodeUUID;
    if (versionId <= 0) {
      riskRow = await handlePost(`/riskanalises/storeRiskRow/${ownerId}/${versionId}`, riskRow);
      versionId = riskRow.status.versionId;
    }

    const newMeasure = await handleQuery(`/riskanalises/addNewMeasure/${versionId}`);
    riskRow.measures = [...riskRow.measures, newMeasure];
    dispatch(enableRAActions());

    showSnackbarChangesSaved(snackbar);
    return { ...riskRow };
  } catch (e) {
    dispatch(enableRAActions());
  }
};

export const uploadMatrixPhotoAsync = async (dispatch, versionId, filedata, snackbar) => {
  dispatch(disableRAActions());
  try {
    await handlePostFile(`/riskrowtemplate/setImage/top/${versionId}`, filedata);

    showSnackbarChangesSaved(snackbar);
    dispatch(enableRAActions());
  } catch (e) {
    dispatch(enableRAActions());
  }
};

export const uploadRiskRowBasisAsync = async (dispatch, riskRow, filedata, snackbar, nodeUUID, ownerId) => {
  dispatch(disableRAActions());
  try {
    let { versionId } = riskRow.status;
    riskRow.status.identifier = nodeUUID;
    if (versionId <= 0) {
      riskRow = await handlePost(`/riskanalises/storeRiskRow/${ownerId}/${versionId}`, riskRow);
      versionId = riskRow.status.versionId;
    }
    const basis = await handlePostFile(`/riskanalises/uploadRiskRowBasis/${versionId}`, filedata);

    riskRow.basis = [...riskRow.basis, basis];
    showSnackbarChangesSaved(snackbar);
    dispatch(enableRAActions());
    return { ...riskRow };
  } catch (e) {
    dispatch(enableRAActions());
  }
};

export const removeRowBasisAsync = async (dispatch, riskRow, basisId, snackbar) => {
  dispatch(disableRAActions());

  try {
    await handleQuery(`/riskanalises/removeRowBasis/${riskRow.status.versionId}/${basisId}`);
    riskRow.basis.splice(basisId, 1);
    riskRow.basis = [...riskRow.basis];
    showSnackbarChangesSaved(snackbar);
    dispatch(enableRAActions());
    return { ...riskRow };
  } catch (e) {
    dispatch(enableRAActions());
  }
};

export const storeRiskRow =
  ({ riskRow, ownerId = -1, nodeUUID }) =>
  (dispatch, getState, hooks) => {
    dispatch(disableRAActions());

    const addRiskRow = createAddRiskRow(nodeUUID);

    try {
      (async () => {
        const { versionId } = riskRow.status;
        riskRow.status.identifier = nodeUUID;
        const storedRiskRow = await handlePost(`/riskanalises/storeRiskRow/${ownerId}/${versionId}`, riskRow);

        if (riskRow.status.versionId === -1) {
          const RA = { ...getState().riskassesment };
          RA.rootNode = addRiskRow(RA.rootNode, storedRiskRow);
          dispatch(fetchRA_Success(RA));
        }

        dispatch(enableRAActions());
        dispatch(fetchElementStored());
        showSnackbarChangesSaved(hooks.snackbar);
      })();
    } catch (e) {
      console.log(e);
    }
    return dispatch(fetchElementStoreStart());
  };

export const storeRiskRowAsync = async (riskRow, ownerId = -1, nodeUUID, dispatch, snackbar) => {
  dispatch(disableRAActions());
  try {
    const { versionId } = riskRow.status;
    riskRow.status.identifier = nodeUUID;
    const storedRiskRow = await handlePost(`/riskanalises/storeRiskRow/${ownerId}/${versionId}`, riskRow);
    dispatch(enableRAActions());
    showSnackbarChangesSaved(snackbar);
    return storedRiskRow;
  } catch (e) {
    showSnackbarError(snackbar, e.message);
    dispatch(enableRAActions());
    return riskRow;
  }
};

export function uploadCover(versionId, filedata, name) {
  return function (dispatch) {
    handlePostFile(`/riskanalises/uploadCover/${versionId}`, filedata)
      .then((basis) => {
        dispatch(fetchCoverUpload(basis));
        dispatch(fetchCoverUpload_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCoverUpload_Begin());
  };
}

export async function addNewSectionAsync(id, uuid, sibling, nodedesc, nodename, dispatch, snackbar) {
  try {
    dispatch(disableRAActions());
    const node = await handleQuery(`/riskanalises/addNewSection/${id}/${uuid}/${sibling}/${nodedesc}/${nodename}`);
    showSnackbarChangesSaved(snackbar);
    dispatch(enableRAActions());
    return node;
  } catch (e) {
    showSnackbarError(snackbar, e.message);
    dispatch(enableRAActions());
  }
}

export function addNewSection(id, uuid, sibling, nodedesc, nodename) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(`/riskanalises/addNewSection/${id}/${uuid}/${sibling}/${nodedesc}/${nodename}`)
      .then((node) => {
        showSnackbarChangesSaved(hooks.snackbar);

        dispatch(fetchAddNewSection(node, uuid, sibling));
        dispatch(fetchAddNewSectionDone());

        dispatch(enableRAActions());
      })
      .catch((err) => {
        dispatch(fetchRA_Error(err));
      });
    return dispatch(fetchAddNewSectionUUID(uuid));
  };
}

export function storeRiskAnalisesDesc(id, uuid, data) {
  return handlePostTextAsParam(`/riskanalises/storeRiskAnalisisNodeDesc/${id}/${uuid}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function addNewMeasure(id) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(`/riskanalises/addNewMeasure/${id}`)
      .then((measure) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(enableRAActions());
        dispatch(fetchAddMeasureDone(measure, id));
        dispatch(fetchAddMeasureFinish());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchAddMeasureStart());
  };
}

export function loadRiskAssessmentOverviewById(id) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/riskrowtemplate/riskRowTemplateByRiskAnalysisId/${locale}/${id}`)
      .then((riskrowtemplate) => {
        dispatch(fetchRA_Template_Success(riskrowtemplate));
        return handleQuery(`/riskanalises/calculateOverview/${id}`);
      })
      .then((riskrowoverview) => {
        dispatch(fetchRA_overview_Success(riskrowoverview));
        dispatch(fetch_RA_overview_DONE());
      })
      .catch((error) => console.log(error));
    return dispatch(fetch_RA_overview_BEGIN(id));
  };
}

export const loadEmptyRiskRowTemplate = (ownerId) => handleQuery(`/riskanalises/emptyRiskRow/${ownerId}`);

const getRiskRowTemplateByRAVersionId = (versionId) =>
  handleQuery(`/riskrowtemplate/riskRowTemplateByRiskAnalysisId/${getLang()}/${versionId}`);

const getMeasureTemplateByRAVersionId = (versionId) =>
  handleQuery(`/riskrowtemplate/measureTemplateByRiskAnalysisId/${getLang()}/${versionId}`);

const getWorkflowTemplateByRAId = (versionId) =>
  handleQuery(`/riskrowtemplate/workflowTemplateByRiskAnalysisId/${getLang()}/${versionId}`);

const getRiskAnalysesByVersionId = (versionId) => handleQuery(`/riskanalises/getById/${versionId}`);

export const loadRiskAnalyses = (versionId) => (dispatch) => {
  (async () => {
    try {
      const riskRowTemplate = await getRiskRowTemplateByRAVersionId(versionId);
      dispatch(fetchRA_Template_Success(riskRowTemplate));

      const emptyRiskRow = await loadEmptyRiskRowTemplate(riskRowTemplate.versionId);
      dispatch(updateEmptyRiskRow(emptyRiskRow));

      const measureTemplate = await getMeasureTemplateByRAVersionId(versionId);
      dispatch(fetchRA_MeasureTemplate_Success(measureTemplate));

      const workflowTemplate = await getWorkflowTemplateByRAId(versionId);
      dispatch(fetchRA_WorkFlowTemplate_Success(workflowTemplate));

      const riskAssessment = await getRiskAnalysesByVersionId(versionId);
      dispatch(fetchRA_Success(riskAssessment));

      dispatch(fetchRA_Loaded());
    } catch (e) {
      dispatch(fetchRA_Error(e));
    }
  })();

  return dispatch(fetchRA_Begin());
};

export const fetchRiskAssessmentTemplates = () => handleQuery('/riskrowtemplate/getTemplates/');

export function loadRiskAssesmentTemplates() {
  return function (dispatch) {
    handleQuery('/riskrowtemplate/getTemplates/')
      .then((templates) => {
        dispatch(fetchRISK_ROWS_TEMPLATES_SUCCESS(templates));
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
      });
    return dispatch(fetchRISK_ROWS_TEMPLATES_BEGIN());
  };
}

export const generateRiskAnalisesReport =
  (report, catalogVersionId, nodeUUID, scope, showImagesSeparatly, includeArchived, reportNum, versionIds) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RiskAssement.GeneratingLabel" defaultMessage="...generating report" />
    );

    handlePost(`/report-react/generateReport/${report.reportUUID}/riskanalisis_generateRiskAnalisisReport`, {
      ...reportMockUp,

      catalogVersionId,
      selectedNodeUUID: 'selectedNodeUUID',
      reportNum: reportNum || 0,
      includeArchived,
      scope,
      riskanalisesscope: 'all',
      showImagesSeparatly,
      locale,
      reportNumber: reportNum,
      includeStatistics: true,
      includeMatrix: true,
      pageBreakBeforeNodes: true,
      nodeUUID: nodeUUID,
      object: JSON.stringify(versionIds)
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, report, 'pdf');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const checkReportStatus = (report, snackbar, generatingSnackbar, generatedReport, mimeType) => {
  handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`)
    .then((newReport) => {
      if (newReport != null && newReport.active) {
        checkReportStatus(newReport, snackbar, generatingSnackbar, generatedReport, mimeType);
      } else {
        snackbar.closeSnackbar(generatingSnackbar);
        createOpenReportSnack(snackbar, generatedReport.reportUUID, mimeType);
      }
    })
    .catch((error) => {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, error);
    });
};

export const generateDetailedXLSRiskAnalysesReport =
  (baseReport, catalogVersionId, nodeUUID, scope, includeAll, id, versionIds) =>
    (dispatch, getState, { snackbar }) => {
      const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage
          id="RiskAssement.GeneratingLabel.spreadsheet"
          defaultMessage="...Exporting risk assessments to SpreadSheet"
        />
      );

      handlePostToApi(`/report-react/reports/?reportType=detailedRiskAssessmentXLS`, {
        ...reportMockUp,
        catalogVersionId,
        nodeUUID: nodeUUID || 'none',
        scope,
        versionIds,
        includeArchived: includeAll,
        locale
      })
        .then((callback) => {
          const report = {...baseReport,
            callbackId: callback.callbackId,
            reportUUID: callback.callbackId};
          checkReportStatus(callback, snackbar, generatingSnackbar, report, 'xlsx');
        })

        .catch((error) => {
          showSnackbarError(snackbar, error);
        });
    };

export const generateXLSRiskAnalisesReport =
  (report, catalogVersionId, nodeUUID, scope, includeAll, startDate, endDate, clientColumns, versionIds) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage
        id="RiskAssement.GeneratingLabel.spreadsheet"
        defaultMessage="...Exporting risk assessments to SpreadSheet"
      />
    );

    handlePost(`/report-react/generateReport/ ${report.reportUUID}/exportRiskAnalysisXLS`, {
      ...reportMockUp,
      catalogVersionId,
      nodeUUID: nodeUUID || 'none',
      scope,

      includeArchived: includeAll,
      locale,
      object: JSON.stringify({
        reportSettings: {
          startDate,
          endDate,
          templateElementId: -1,
          clientColumns
        },
        versionIds
      })
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, report, 'xls', null, null, 'export');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export function initRiskAssesment(idTemplate, data, placement1, folderId, versionId, activityId) {
  let idElement = -1;
  return function(dispatch) {
    handlePost(`/riskanalises/initRiskAssesment/${idTemplate}`, data)
      .then((info) => {
        idElement = info;
        if (folderId) {
          return addElementsToFolderApi(folderId, [idElement]);
        }
        return null;
      })
      .then(() => {
        return placeElementNew(idElement, placement1);
      })
      .then(() => {
        dispatch(fetchElementCreated(idElement, versionId, activityId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchElementCreate());
  };
}
