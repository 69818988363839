import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import DialogDownloadImg from "./dialog/DialogDownloadImg";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAddPhrase from "@zert-packages/components/dialog/DialogAddPhrase/DialogAddPhrase";
import DialogHotspot from "./dialog/dialoghotsport/DialogHotspot";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import DialogNewForm from "./dialog/DialogNewForm";
import DialogArrow from "./dialog/DialogArrow";
import { getImageContent } from "./API";




const useStyles = makeStyles((theme) => ({
  root: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexWrap: 'wrap',
    border: "1px solid #d3d3d3",
    borderRadius: 5,
    marginBottom: 10
  },
  itemMenu: {
    cursor: "pointer",
    padding: "5px 10px 5px 6px",
    borderRight: "1px solid #919191",
    "&:hover": {
      backgroundColor: "#4db1d3",
      color: "#ffffff"
    }
  }
}));

const HeaderImg = ({ setHotspots, el, hotspots, values,  setCanvas, context}) => {
  const cl = useStyles();
  const [openPhrase, setOpenPhrase] = useState(false);
  const { setValues } = useContext(context || PanelContext);

  const addSaveBlock = () => {
    setValues(pre => ({...pre, changeBlock: true}))
  }


  const handleReduce = () => {
    const res = +(hotspots.percents  / 1.5).toFixed(0)
    if(13 > res){
      return  setHotspots(pre => ({ ...pre, percents: 13 }));
    }
    return  setHotspots(pre => ({ ...pre, percents: res }));
  };

  const handleIncrease = () => {
    const res = +(hotspots.percents  * 1.5).toFixed(0)
    if(2566  < res){
      return  setHotspots(pre => ({ ...pre, percents: 2566  }));
    }
    return  setHotspots(pre => ({ ...pre, percents: res }));
  };

  const DownloadImg = async () => {
    const res = await getImageContent(el.elementInfo.versionId);
    const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
    const link = document.createElement("a");
    link.href = url;
    link.download = res.headers["content-disposition"].split("=")[1].replace(/(\.[^.]+)\1+/g, "$1");
    link.click();
    link.remove();

  };

  const saveImg = () => {
    renderInExceptionRoot(DialogDownloadImg, {action: addSaveBlock});
  };
  const AddPhrase = () => {
    setOpenPhrase(true)
  };
  const addHotspot = () => {
    renderInExceptionRoot(DialogHotspot, {setValues, values, action: addSaveBlock});
  }
  const addNewForm = () => {
    renderInExceptionRoot(DialogNewForm, {setValues, action: addSaveBlock});
  }

  const addNewArrow = () => {
    renderInExceptionRoot(DialogArrow, {setValues, action: addSaveBlock});
  }

  const removeItem = () => {
    setCanvas(pre => ({
      ...pre,
      item: pre.item.reduce((acu,cur) => {
        if(cur.id === values.selectedItem.id){
          return acu
        }
        return [...acu, cur]
      }, []),
      selectedItem: null
    }))
  }

  const dataMenu = [
    { name: "Upload", onClick: saveImg },
    { name: "Download", onClick: DownloadImg },
    { name: "Add new phrase", onClick: AddPhrase },
    { name: "New hotspot",  onClick: addHotspot},
    { name: "New Form",  onClick: addNewForm},
    { name: "New arrow", onClick:  addNewArrow},
    { name: "Zoom in", onClick: handleIncrease },
    { name: "Zoom out", onClick: handleReduce }
  ]
  
  const handleAlignText = (val) => {
    setCanvas(pre => ({
      ...pre,
      item: pre.item.reduce((acu,cur) => {
        if(cur.id === values.selectedItem.id){
          return [...acu, { ...cur, align: val.toUpperCase()}
          ]
        }
        return [...acu, cur]
      }, [])
    }))
  }

  return (<>
    <ul className={cl.root}>
      {dataMenu.map((el, i) => <li key={i} className={cl.itemMenu} onClick={el.onClick}>
        {el.name}
      </li>)}
      {values.selectedItem  &&  <li className={cl.itemMenu}  onClick={removeItem}>Remove</li>}
      {values.selectedItem?.label && <>
        <li className={cl.itemMenu}  onClick={() => handleAlignText('left')}>Align left</li>
        <li className={cl.itemMenu}  onClick={() => handleAlignText('center')}>Align center</li>
        <li className={cl.itemMenu}  onClick={() => handleAlignText('right')}>Align right</li>
        <li className={cl.itemMenu}  onClick={removeItem}>Lock reference</li>
        <li className={cl.itemMenu}  onClick={removeItem}>Unlock reference</li>
      </>}
    </ul>
    <DialogAddPhrase
      openPhrase={openPhrase}
      setOpenPhrase={setOpenPhrase}
      context={context}
    />
  </>);
};

export default HeaderImg;