import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useResizeObserver } from '@zert-packages/react/hooks/useResizeObserver';
import { handleQuery } from '@zert-packages/actions/api';
import useTableFilter from '../shared/SearchableTable/useTableFilter';
import { descendingComparator } from '../ElementTile/utils/descendingComparator';
import { stableSort } from '../ElementTile/utils/stableSort';
import EnhancedTableToolbar from './EnhancedTableToolbar';

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    doNotShowSelect,
    showDetails,
    rowCount,
    onRequestSort,
    columns,
    showElementActions,
    headerRef
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead ref={headerRef}>
      <TableRow>
        {showDetails && <TableCell padding="checkbox" width={20} />}
        {showElementActions && <TableCell />}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.identifier}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.identifier ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.identifier}
              direction={orderBy === headCell.identifier ? order : 'asc'}
              onClick={createSortHandler(headCell.identifier)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '5px 10px',
    [theme.breakpoints.down('lg')]: {
      padding: '2px 5px'
    }
  },
  paper: {
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  pagination: {
    overflowY: 'hidden',
    '& .MuiToolbar-root': {
      '& .MuiInputBase-root': {
        '& .MuiSelect-select': {
          paddingTop: '0.5em'
        }
      }
    }
  }
}));

function EnhancedTable({
  identifier,
  columns,
  rows,
  sortingKey,
  doNotShowToolbar,
  sortingOrder,
  detailComponent,
  customSelectionCallback,
  doNotShowSelect,
  selectOnlyOne,
  maxHeight,
  actions,
  otherActions,
  loading,
  getCustomFilter,
  showElementActions,
  bradCrumbComponent,
  selectionModel,
  showCut= false,
  showFileUpload = false,
  pagination = 100,
  minWidth = true,
  showFilter = false,
  ...other
}) {
  const classes = useStyles();
  const {
    selected,
    setSelected,
    selectedRows,
    setSelectedRows,
    handleClick,
    handleDoubleClick,
    handleTouchStart,
    handleTouchEnd
  } = selectionModel;
  const [order, setOrder] = React.useState(sortingOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(sortingKey || columns[0].identifier);
  const [page, setPage] = React.useState(0);
  const { filterValue, filteredRows, updateFilterValue } = useTableFilter(rows, columns, getCustomFilter);
  const [isShowingFilter, setIsShowingFilter] = useState(showFilter);

  const [rowsPerPage, setRowsPerPage] = React.useState(pagination);

  const tableToolbarRef = useRef(null);
  const tableHeaderRef = useRef(null);

  const [tableToolbarWidth, tableToolbarHeight] = useResizeObserver(tableToolbarRef);
  const [tableHeaderWidth, tableHeaderHeight] = useResizeObserver(tableHeaderRef);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[identifier]);
      setSelected(newSelecteds);
      setSelectedRows(rows);
      return;
    }
    setSelectedRows([]);
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function getComparator(order, orderBy) {
    const columnSort = columns.find((column) => column.identifier === orderBy);
    const customComparator =
      columnSort && columnSort.customComparator ? columnSort.customComparator : descendingComparator;

    return order === 'desc'
      ? (a, b) => customComparator(a, b, orderBy, order)
      : (a, b) => -customComparator(a, b, orderBy, order);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const sorted = stableSort(filteredRows, getComparator(order, orderBy));

  useEffect(() => {
    if (selectedRows.length > 0) {
      const index = sorted.findIndex((row) => row[identifier] === selected[0]);
      const page = Math.floor(index / rowsPerPage);

      if (page > 0) {
        setPage(page);
      }
    }
  }, [rows]);

  useEffect(() => {
    setPage(0);
    if (selectedRows.length > 0) {
      const index = sorted.findIndex((row) => row[identifier] === selected[0]);
      const page = Math.floor(index / rowsPerPage);

      if (page > 0) {
        setPage(page);
      }
    }
  }, [filterValue]);

  const displayRows = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const emptyRows = Math.min(35, 35 - displayRows.length);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {!doNotShowToolbar && (
          <EnhancedTableToolbar
            selected={selectedRows}
            actions={actions}
            filterValue={filterValue}
            updateFilterValue={updateFilterValue}
            isShowingFilter={isShowingFilter}
            setIsShowingFilter={setIsShowingFilter}
            showElementActions={showElementActions}
            bradCrumbComponent={bradCrumbComponent}
            showCut={showCut}
            showFileUpload={showFileUpload}
            {...other}
            otherActions={otherActions}
            toolbarRef={tableToolbarRef}
          />
        )}
        <TableContainer
          style={{
            height: tableToolbarHeight && tableToolbarHeight !== 0 ? `calc(100% - ${tableToolbarHeight}px)` : 'initial',
            maxHeight
          }}
        >
          <Table
            className={minWidth ? classes.table : ''}
            aria-labelledby="tableTitle"
            size="small"
            stickyHeader
            aria-label="enhanced table"
            style={{
              height:
                loading && tableToolbarHeight && tableToolbarHeight !== 0
                  ? `calc(100% - ${tableToolbarHeight}px)`
                  : 'initial'
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              columns={columns}
              orderBy={orderBy}
              showDetails={detailComponent}
              doNotShowSelect={doNotShowSelect}
              showElementActions={showElementActions}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
              headerRef={tableHeaderRef}
            />
            <TableBody
              style={{
                height: tableHeaderHeight && tableHeaderHeight !== 0 ? `calc(100% - ${tableHeaderHeight}px)` : 'initial'
              }}
            >
              {loading ? (
                <TableRow style={{ height: '100%' }}>
                  <TableCell colSpan="100%" style={{ height: '100%' }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {displayRows && displayRows.length > 0 ? (
                    displayRows.map((row, index) => {
                      const isItemSelected = isSelected(row[identifier]);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <InfoRow
                          key={`infoRow${row[identifier]}`}
                          row={row}
                          actions={actions}
                          doNotShowSelect={doNotShowSelect}
                          showElementActions={showElementActions}
                          otherActions={otherActions}
                          detailComponent={detailComponent}
                          columns={columns}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          identifier={identifier}
                          handleClick={handleClick}
                          handleDoubleClick={handleDoubleClick}
                          handleTouchStart={handleTouchStart}
                          handleTouchEnd={handleTouchEnd}
                          displayRows={displayRows}
                        />
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan="100%" align="center">
                        <FormattedMessage id="EnhancedTable.Empty" defaultMessage="Empty" />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
              {/*                            {emptyRows > 0 && (
                                <TableRow style={{ height: 24 * emptyRows }}>
                                    <TableCell colSpan={columns.length +    (showElementActions ? 3 : 2)} />
                                </TableRow>
                            )} */}
            </TableBody>
          </Table>
        </TableContainer>

        {filteredRows.length > pagination && (
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[10, 100, 200, 1000]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<FormattedMessage id="EnhancedTable.rowsPerPage" defaultMessage="Rows per page:" />}
            labelDisplayedRows={({ from, to, count }) => {
              return (
                <FormattedMessage
                  id="EnhancedTable.countOfTotal"
                  defaultMessage="{from} - {to} of {count}"
                  values={{ from, to, count }}
                />
              );
            }}
          />
        )}
      </Paper>
    </div>
  );
}

const BACKGROUND_COLOR = '#dadada';

const useRowStyles = makeStyles({
  rootg: {
    '& > *': {
      borderBottom: 'unset'
    }
  },

  rootT: {
    paddingLeft: 0,
    paddingRight: 0
  },

  '@keyframes opacityAnimation': {
    '0%': {
      opacity: 0.6
    },
    '50%': {
      opacity: 0.85
    },
    '100%': {
      opacity: 0.6
    }
  },
  square: {
    width: '24px',
    height: '24px',
    borderRadius: 2,
    background: BACKGROUND_COLOR,
    marginRight: 10
  },
  root: {
    height: 24,
    /* marginBottom: 5, */
    boxSizing: 'border-box',
    /* padding: '6px 12px', */
    animation: `$opacityAnimation 2s infinite`,
    /*  display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: 'auto 1fr auto', */
    alignItems: 'center'
  },

  bar: {
    background: BACKGROUND_COLOR,
    borderRadius: 3
  },
  barTitle: {
    width: '75%',
    height: 18,
    marginBottom: 8
  }
});

const getDisplayValue = (row, identifier) => {
  const directCall = () => _.get(row, `[${identifier}]`, '-');
  return _.get(row, `[${identifier}].displayValue`, directCall());
};

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

export function InfoRow(props) {
  const {
    row,
    isItemSelected,
    labelId,
    handleClick,
    columns,
    doNotShowSelect,
    identifier,
    actions,
    otherActions,
    showElementActions,
    background = 'none',
    detailComponent: Component,
    displayRows,
    handleDoubleClick,
    handleTouchStart,
    handleTouchEnd
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const selected = [row];
  const { elementActions, getMenuActions, performActionHandler, toolbarActions } = actions({
    selected,
    ...otherActions
  });

  const replacerRef = useRef();
  const actionsMenu = showElementActions && getMenuActions ? getMenuActions(row) : [];
  const previousVersionId = useRef(null);
  /* const [workflowTemplate, setWorkflowTemplate] = useState(null);
  const [element, setElement] = useState(null); */
  const [canMount, setCanMount] = useState(false);
  /*    const [error, setError] = useState(null);
      const isTask = checkIsTask(props.element.mimeType); */

  const [canShowElement, setCanShowElement] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(replacerRef.current);
    return () => observer.disconnect();
  }, []);

  const observerCallback = ([entry]) => {
    if (entry.intersectionRatio > 0.1) {
      setCanMount(true);
    }
  };

  useEffect(() => {
    if (!canMount) return;

    setCanShowElement(true);
  }, [canMount]);

  return (
    <>
      <TableRow
        className={canShowElement ? '' : classes.rootg}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row[identifier]}
        selected={isItemSelected}
        style={{
          backgroundColor: row.background ? row.background : 'none',
          userSelect: isItemSelected ? 'none' : 'auto',
          cursor: 'pointer'
        }}
        onClick={(event) => handleClick(event, row[identifier], displayRows)}
        onDoubleClick={(event) => handleDoubleClick(event, row)}
        onTouchStart={(event) => handleTouchStart(event, row[identifier])}
        onTouchEnd={(event) => handleTouchEnd(event, row)}
      >
        {canShowElement ? (
          <>
            {Component && (
              <TableCell width={20} padding="none">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                  }}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            )}
            {actionsMenu.length > 0 && (
              <TableCell style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#fafafa' }}>
                <Toolbar className={clsx(classes.rootT)}>
                  {actionsMenu.map((action) => (
                    <Tooltip title={action.name}>
                      <IconButton
                        className={action.class}
                        onClick={(e) => {
                          e.stopPropagation();
                          performActionHandler([row])(action.id);
                        }}
                        size="large"
                      >
                        {action.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
                </Toolbar>
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                align={column.align}
                component={column.component}
                scope={column.scope}
                className={column.style}
                key={column.identifier}
              >
                {getDisplayValue(row, column.identifier)}
              </TableCell>
            ))}
          </>
        ) : (
          <TableCell
            className={classes.root}
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 3}
            ref={replacerRef}
          />
        )}
      </TableRow>

      {canShowElement && open && Component && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#fafafa' }}
            colSpan={columns.length + (showElementActions ? 3 : 2)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <PlacementInfo versionId={row.versionId} />
                <Component selectedElement={row} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function PlacementInfo({ versionId }) {
  const [placement, setPlacement] = useState(null);
  const [loadingPlacement, setLoadingPlacement] = useState(true);

  useEffect(() => {
    handleQuery(`/catalog/getPlacement/${versionId}?useNames=true`)
      .then((catalogPlacementData) => {
        setPlacement(catalogPlacementData);
        setLoadingPlacement(false);
      })
      .catch(() => {
        setLoadingPlacement(false);
      });
  }, []);

  return (
    <div style={{ marginLeft: '25px' }}>
      {loadingPlacement ? (
        <FormattedMessage id="EnhancedTable.searchingForPlacement" defaultMessage="Loading placement" />
      ) : (
        <>
          {placement ? (
            <>
              <FormattedMessage id="EnhancedTable.placement" defaultMessage="Placement: " />
              <ul style={{ color: '#80A2AC', display: 'inline', padding: '10px' }}>
                {placement.map((p) => (
                  <li style={{ marginRight: '10px', display: 'inline-block' }}>{p}</li>
                ))}
              </ul>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

InfoRow.propTypes = {
  row: PropTypes.object.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  labelId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  identifier: PropTypes.string.isRequired
  /* row: PropTypes.shape({
       calories: PropTypes.number.isRequired,
       carbs: PropTypes.number.isRequired,
       fat: PropTypes.number.isRequired,
       history: PropTypes.arrayOf(
           PropTypes.shape({
               amount: PropTypes.number.isRequired,
               customerId: PropTypes.string.isRequired,
               date: PropTypes.string.isRequired,
           }),
       ).isRequired,
       name: PropTypes.string.isRequired,
       price: PropTypes.number.isRequired,
       protein: PropTypes.number.isRequired,
   }).isRequired, */
};

export default EnhancedTable;
