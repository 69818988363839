import React, { useCallback, useContext, useEffect, useRef, useState, createContext } from 'react';
import { PanelContext } from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import SkeletonLoader from '@zert-packages/components/SkeletonLoader';
import AbstractWrapper from '@zert-packages/components/InfoElement/components/AbstractWrapper';
import BorderTypeColor from '@zert-packages/components/InfoElement/helpers/BorderTypeColor';
import addId from '@zert-packages/utils/addId';
import { findSelectedBlock } from '@zert-packages/components/InfoElement/helpers/findSelectedBlock';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import styleAccordionWrapper from './components/style/styleAccordionWrapper';
import {
  addItem,
  addItemId,
  findBlockChild,
  findBorderItem,
  findSelectedTreeItem,
  getChild,
  getCountChild,
  handleValue
} from './utils/main';
import AddToolbar from './components/AddToolbar';
import CreateBlockItems from './CreateBlockItems';
import AddBlockItemIcons from './AddBlockItemIcons';
import IndexText from './components/IndexText';
import { getBlockApi } from './API';
import { findActiveScrollItem } from './utils/findActiveScrollItem';
import { findIndexCreateBlock } from './utils/findIndex';
import PhraseBlockItemView from '../PhrasePlugin/PhraseBlockItemView';
import AddItemToList from './AddItemToList';
import { blockData, closeData, defaultSaveData, parentCreateData } from './data/dataBlockItems';
import { handleCheckin, handleCheckout } from './utils/handleCheckin';
import ImgTypeWrapper from '../ImagePlugin/ImgTypeWrapper';
import CustomTable from '../TablePlugin/resizedTable/CustomTable';

export const BlockItemsContext = createContext(null);

function BlockItems(props) {
  const {
    el,
    parent,
    context,
    investmentLevel,
    selected,
    index,
    newFraze,
    parentChild,
    selectedRange = {
      loaderBlockItems: null,
      selectedBlockChild: null
    },
    setSelectedRange,
    currentFocus,
    left,
    arrayParent,
    looksState,
    handlePhrase,
    parentItemCreate,
    AddBlockItems,
    expandedToolbarProps,
    createElement,
    isLastItem = false,
    blockAddLast,
    versionIdParent
  } = props;

  const { values, setValues } = useContext(context || PanelContext);
  const { myLanguage } = useSelector((state) => state);
  const { loaderBlockItems, selectedBlockChild } = selectedRange;
  const [block, setBlock] = useState(blockData);
  const cl = styleAccordionWrapper({ expanded: !!block.colapse });
  const [renderState, setRenderState] = useState(false);
  const ref = useRef(null);
  const expendBlock = values.investmentLevel > 1 && investmentLevel !== 1 && investmentLevel !== -100;
  const type = el?.elementInfo?.mimeType.split('/').pop();
  // const selectedBlock = (el?.identifier && (values.closeChild !== el?.identifier) && ((values?.treeItem?.identifier === el.identifier)));
  const selectedBlock = findSelectedBlock(context, values, el);
  const dropMenuSelected = selected && type === 'zert-block';
  const blockRedactor = !values.blockKey && !values.blockKeyRedactor;
  const findParentChild = parentChild?.identifier === el?.identifier && investmentLevel !== 0;
  const blockClick = values.blockClickCreateItemsBlock;
  const findActiveSearch = findActiveScrollItem(values, el, type);
  const findInvestmentLevel = selected ? 0 : investmentLevel + 1;
  const indexCreateElement = findIndexCreateBlock(block?.child);
  const findBorder = findBorderItem(el, values, selectedRange, selected);
  const data = defaultSaveData(parentChild, investmentLevel);
  const dataParentItem = parentCreateData(block, el, findInvestmentLevel, myLanguage);
  const closeChild = values.closeChild !== el?.identifier;


  async function handleSelectedBlock(e, el) {
    if (e) {
      if (e.target.name !== 'dontBlock') {
        e.stopPropagation();
        e.preventDefault();
      }
    }

    if (
      blockClick ||
      (values.closeChild !== values.treeItem?.identifier &&
        el?.identifier &&
        el?.identifier === values.treeItem?.identifier)
    ) {
      return setBlock((pre) => ({ ...pre, colapse: false }));
    }
    if (e.ctrlKey) {
      if (values.investmentLevel === investmentLevel) {
        const severalSelectedTreeItem = findSelectedTreeItem(values, {
          ...el,
          parent: parentItemCreate,
          indexElementCreate: index
        });
        return setValues((pre) => ({ ...pre, severalSelectedTreeItem }));
      }
      return;
    }
    if (handlePhrase) {
      // handlePhrase(el, "activePhrase");
    }
    if (investmentLevel === 0) {
      handleValue(false, false, setSelectedRange, {
        lastSelectedIndex: index,
        blockParentSelectedLvl: false,
        parentSelectedLvl: null
      });
    } else {
      handleValue(false, false, setSelectedRange, {
        lastSelectedIndex: index,
        blockParentSelectedLvl: false,
        parentSelectedLvl: { ...el, child: { ...parentChild, items: arrayParent } }
      });
    }
    setBlock((pre) => ({ ...pre, colapse: false }));
    if (selected) {
      setValues((pre) => ({
        ...pre,
        dropBlockTableCloseChild: false,
        blockKey: true,
        getTreeItemChild: false,
        closeAllBlockItems: false
      }));
      return;
    }

    const beforeTreeItem = values.beforeTreeItem
      ? [
          ...values.beforeTreeItem,
          {
            ...el,
            parent: parentItemCreate,
            indexElementCreate: index
          }
        ]
      : [];

    if (!el?.elementInfo.approvedAt) {
      await handleCheckout(el.elementInfo.versionId);
      if (values.checkin) {
        handleCheckin(values.checkin.elementInfo.versionId);
      }
    }

    setValues((pre) => ({
      ...pre,
      ...data,
      beforeTreeItem,
      treeItem: { ...el, parent: parentItemCreate, indexElementCreate: index },
      checkin: el?.elementInfo.approvedAt ? false : el
      // action: {}
    }));
  }

  const handleExpanded = async () => {
    if (!context && el?.elementInfo && !block.child) {
      setBlock((pre) => ({ ...pre, colapse: false }));
      await getChild(el, setBlock, el.elementInfo.locale);
      return;
    }
    setBlock((pre) => ({ ...pre, colapse: !pre.colapse }));
  };

  const getParentTreeItem = async (elementInfo) => {
    if (block.child) {
      return;
    }
    handleValue(true, 'loader', setBlock);
    const res = await getBlockApi(elementInfo.versionId, elementInfo.locale);
    if (res) {
      handleValue(res, 'parentItemCreate', setBlock, {
        parentItemCreate: res,
        child: { ...res, items: addId(res.items) },
        loader: false
      });
    }
  };

  const blockClickParent = (e) => {
    if (e.target.name !== 'dontBlock') {
      e.stopPropagation();
      e.preventDefault();
    }
  };
  const handleBlock = (val, name) => {
    handleValue(val, name, setBlock);
  };

  const findPhraseVue = () => {
    if (el.softWareText) {
      return true;
    }
    return closeChild && (selectedBlock || selected);
  };

  useEffect(() => {
    if (!block.child) {
      if (
        (findBorder || (selectedBlockChild?.identifier === el?.identifier && currentFocus === index)) &&
        !findParentChild
      ) {
        findBlockChild(selectedBlockChild, el, setBlock, setSelectedRange, arrayParent, index);
      }
    }
  }, [loaderBlockItems, selectedBlockChild, values.treeItem, currentFocus]);

  useEffect(() => {
    if (findBorder && left && blockRedactor && investmentLevel !== 0) {
      handleValue(false, false, setSelectedRange, {
        selectedBlockChild: null,
        lastSelectedIndex: index,
        parentSelectedLvl: parentChild,
        blockParentSelectedLvl: investmentLevel === 1 ? false : parentChild
      });
      handleValue(false, false, setValues, {
        closeChild: { ...parentChild },
        investmentLevel: values.investmentLevel - 1,
        treeItem: parentChild,
        closeAllBlockItems: false
      });
    }
  }, [left, values.blockKey, values.blockKeyRedactor, looksState]);

  const handleGetSelectedBlock = useCallback(() => {
    if (values.getTreeItemChild && findBorder) {
      handleSelectedBlock(false, values.getTreeItemChild);
    }
  }, [values.getTreeItemChild]);

  useEffect(() => {
    handleGetSelectedBlock();
  }, [handleGetSelectedBlock]);

  useEffect(() => {
    if (values.parentSelectedLvlTrue && findBorder) {
      if (investmentLevel === 0) {
        handleValue(false, false, setSelectedRange, {
          lastSelectedIndex: index,
          blockParentSelectedLvl: false,
          parentSelectedLvl: null
        });
      } else {
        handleValue(false, false, setSelectedRange, {
          lastSelectedIndex: index,
          blockParentSelectedLvl: false,
          parentSelectedLvl: {
            ...el,
            child: { items: arrayParent.filter((el) => el?.identifier !== values.treeItemDelete?.identifier) }
          }
        });
      }
      handleValue(false, false, setValues, { parentSelectedLvlTrue: false });
    }
  }, [values.parentSelectedLvlTrue]);

  useEffect(() => {
    if (values.treeItemDelete && block.child) {
      const res = block.child.items.filter((el) => el?.identifier !== values.treeItemDelete?.identifier);
      if (el?.identifier === values.treeItemDelete?.identifier) {
        return setBlock((pre) => ({ ...pre, child: null }));
      }
      setBlock((pre) => ({ ...pre, child: { ...pre.child, items: res } }));
    }
  }, [values.treeItemDelete]);

  useEffect(() => {
    if (values.pastItem && block.child?.items.length > 0) {
      const findPastItem = block.child.items.find((el) => el.identifier === values.treeItem.identifier);
      if (findPastItem) {
        const adobeCopyBlockItems = addItemId(values.pastItem);
        const res = addItem(block.child.items, adobeCopyBlockItems, currentFocus);
        setBlock((pre) => ({ ...pre, child: { ...pre.child, items: res } }));
        setValues((pre) => ({ ...pre, ...data, treeItem: adobeCopyBlockItems }));
      }
    }
  }, [values.pastItem]);

  const treeItemRef = useCallback(
    (node) => {
      // if (node && el?.identifier && findActiveSearch) {
      //   return node.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center"
      //   });
      // }
      // if (node && el?.identifier && values?.treeItem?.identifier === el?.identifier && !selected) {
      //   node.scrollIntoView({ behavior: "smooth" });
      // }
    },
    [values?.treeItem && values?.treeItem?.identifier, findActiveSearch]
  );

  useEffect(() => {
    if (values.closeAllBlockItems) {
      setBlock((pre) => ({ ...pre, ...closeData }));
    }
  }, [values.closeAllBlockItems]);

  useEffect(() => {
    if (
      (values?.action?.parentItemCreate && values.action?.parentItemCreate?.elementInfo.id === el?.elementInfo.id) ||
      createElement
    ) {
      setRenderState((pre) => !pre);
      setBlock((pre) => ({ ...pre, child: values.action.parentItemCreate }));
    }
  }, [values?.action?.parentItemCreate]);

  useEffect(() => {
    if (el) {
      setBlock((pre) => ({ ...pre, blockItem: el }));
    }
  }, [el, values.treeItem]);

  useEffect(() => {
    if (findBorder && !createElement) {
      setValues((pre) => ({ ...pre, action: { ...pre.action, indexElementCreate: index } }));
    }
    if (!AddBlockItems && findBorder && !context && el?.elementInfo && type === 'zert-block') {
      getParentTreeItem(el.elementInfo);
    }
  }, [values.treeItem]);

  useEffect(() => {
    if (investmentLevel === 0 && type === 'zert-block') {
      getCountChild(el?.elementInfo?.versionId, handleExpanded);
    }
  }, []);

  useEffect(() => {
    if (
      selectedBlock &&
      block.expandedToolbarCreateLoad &&
      values.expandedTreeItem &&
      el.elementInfo.versionId === values?.expandedTreeItem?.elementInfo.versionId
    ) {
      setValues((pre) => ({ ...pre, expandedTreeItem: null }));
      setBlock((pre) => ({ ...pre, expandedToolbarProps: true }));
    }
  }, [values.expandedTreeItem]);

  useEffect(() => {
    if (expandedToolbarProps) {
      setBlock((pre) => ({ ...pre, expandedToolbar: true }));
    }
  }, [expandedToolbarProps]);

  // !el?.elementInfo?.approvedAt



  return (
    <BlockItemsContext.Provider value={{ block, setBlock }}>
      <AddItemToList block={parentItemCreate} el={block.blockItem} index={index}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={0}
          marginTop={index === 0 ? '12px' : 0}
          marginBottom={isLastItem ? '12px' : 0}
        >
          <div name="allowСlick" className={cl.wrapperAddIcon}>
            <AddBlockItemIcons
                blockClick={findBorder && blockClick}
                block={block}
                setBlock={setBlock}
                handleBlock={handleBlock}
                index={index}
                el={el}
            />

            {!context && el && type === 'zert-block' && (
              <div
                className={`${cl.expandableWrapper} ${block?.expandedToolbar ? cl.expandableWrapperAdding : ''}`}
                onClick={handleExpanded}
              >
                <div className={`${cl.arrow} ${block.colapse ? cl.expand : cl.collapse}`}>
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            )}

            {isLastItem && !blockAddLast && (
              <AddBlockItemIcons
                blockClick={findBorder && blockClick}
                block={block}
                setBlock={setBlock}
                index={index}
                el={el}
                handleBlock={handleBlock}
                type="expandedToolbarBottom"
              />
            )}
          </div>
          <div
            style={{
              width: '100%'
              // boxShadow: findBorder ? '-12px 0 15px -4px rgba(0, 0, 0, 0.1)' : ''
            }}
            // name="allowСlick"
            onMouseEnter={() => handleBlock(true, 'isHoveredBlock')}
            onMouseLeave={() => handleBlock(false, 'isHoveredBlock')}
          >
            <div ref={treeItemRef} className={createElement ? cl.createElementWrapper : cl.wrapper}>
              <div onClick={blockClickParent}>
                <div
                  className={cl.wrapperRoot}
                  style={{
                    paddingLeft: !dropMenuSelected && selected ? 0 : ''
                  }}
                >
                  {block?.expandedToolbar && (
                    <>
                      <AddToolbar setSelectedRange={setSelectedRange} parentItemCreate={parentItemCreate} />
                      {block.createType && (
                        <CreateBlockItems
                          parentItemCreate={parentItemCreate}
                          context={context}
                          setSelectedRange={setSelectedRange}
                          createType={block.createType}
                          props={props}
                          versionIdParent={versionIdParent}
                        />
                      )}
                    </>
                  )}

                  {!createElement && (
                    <div style={{ cursor: blockClick && !findBorder ? 'not-allowed' : '' }}>
                      <div className={cl.border}>{block.isHoveredAdd && !block.expandedToolbar && <div />}</div>
                      <div
                        className={cl.wrapperBlockItems}
                        onClick={(e) => handleSelectedBlock(e, el)}
                        ref={ref}
                        style={{
                          borderRadius: index === 0 ? '5px 5px 0px 0px' : isLastItem ? '0px 0px 5px 5px' : '',
                          borderTop:
                            index !== 0
                              ? findBorder
                                ? '1px solid #01A1C7'
                                : '1px solid rgba(145, 145, 145, 0.125)'
                              : findBorder
                              ? '1px solid #01A1C7'
                              : '1px solid rgba(145, 145, 145, 0.25)',
                          borderLeft: findBorder ? '1px solid #01A1C7' : '1px solid rgba(145, 145, 145, 0.25)',
                          borderRight: findBorder ? '1px solid #01A1C7' : '1px solid rgba(145, 145, 145, 0.25)',
                          borderBottom: !isLastItem
                            ? findBorder
                              ? '1px solid #01A1C7'
                              : '1px solid rgba(145, 145, 145, 0.125)'
                            : findBorder
                            ? '1px solid #01A1C7'
                            : '1px solid rgba(145, 145, 145, 0.25)',
                          paddingBottom:
                            (values?.treeItem?.identifier === el?.identifier ||
                              selected ||
                              index === parent?.item?.length) &&
                            type === 'zert-block'
                              ? 5
                              : 0
                        }}
                      >
                        <BorderTypeColor el={{ ...el, ...el?.elementInfo }} context={context} />
                        <div className={cl.wrapperText} style={{ paddingBottom: selected ? 0 : 1 }}>
                          <AbstractWrapper
                            blockItems
                            findBorder={findBorder}
                            child
                            id={el?.elementInfo?.versionId}
                            info={el?.elementInfo}
                            parent={parent}
                            expendBlock={expendBlock}
                            values={values}
                            el={block.blockItem}
                            context={context}
                            setValues={setValues}
                            setBlock={setBlock}
                            block={block}
                            blockClick={blockClick}
                            parentItemCreate={parentItemCreate}
                            selectedRange={selectedRange}
                          />
                          {block.loader ? (
                            <div className={cl.wrapperRoot}>
                              <SkeletonLoader height={38} />
                            </div>
                          ) : (
                            <div>
                              {(type === 'zert-block' ||
                                type === 'zert-image' ||
                                type === 'zert-table' ||
                                type === 'zert-index') &&
                                !selectedBlock &&
                                (block.colapse || block.colapse == null) && (
                                  <span
                                    className={cl.title}
                                    style={{
                                      paddingBottom: type === 'zert-table' ? 0 : '',
                                      color: findActiveSearch ? 'red' : ''
                                    }}
                                  >
                                    {el?.elementInfo?.name}
                                  </span>
                                )}
                              {selectedBlock && type === 'zert-index' && <IndexText />}
                              {/* selectedBlock || selected */}
                              {type === 'zert-phrase' && (
                                <div
                                  style={{
                                    marginTop: selectedBlock ? 5 : 0,
                                    color: findActiveSearch ? 'red' : '',
                                    fontWeight: findActiveSearch ? 'bold' : ''
                                  }}
                                >
                                  <PhraseBlockItemView
                                    block={block}
                                    vue={findPhraseVue()}
                                    context={context}
                                    newFraze={newFraze}
                                  />
                                </div>
                              )}

                              {type === 'zert-image' && block.blockItem && (
                                <ImgTypeWrapper setBlock={setBlock} el={block.blockItem} vue={!selectedBlock} />
                              )}
                              {type === 'zert-table' && (
                                <div style={{ marginTop: 5 }}>
                                  <CustomTable
                                    versionId={el.elementInfo.currentVersionId}
                                    vue={!selectedBlock}
                                    insertedBlock={true}
                                    actionTypeProps={block.table}
                                  />
                                  {/* <TableEditorWrapper setBlock={setBlock} el={el} vue={!selectedBlock} /> */}
                                </div>
                              )}
                              <div
                                style={{
                                  marginBottom: selectedBlock ? 5 : 1,
                                  display: block.colapse ? 'none' : 'block'
                                }}
                              >
                                {(closeChild || values.dropBlockTableCloseChild !== el?.identifier) &&
                                  block.child?.items.length > 0 &&
                                  block.child.items.map((it, i, arr) => {
                                    const isLastItem = i === arr.length - 1;
                                    return (
                                      <div key={it.identifier}>
                                        <BlockItems
                                          index={i}
                                          selectedRange={selectedRange}
                                          setSelectedRange={setSelectedRange}
                                          selected={selected}
                                          el={it}
                                          isLastItem={isLastItem}
                                          parent={parent}
                                          parentChild={{ ...el, index: i, child: { items: arrayParent } }}
                                          investmentLevel={findInvestmentLevel}
                                          currentFocus={currentFocus}
                                          left={left}
                                          arrayParent={block.child.items}
                                          looksState={looksState}
                                          parentItemCreate={{ ...dataParentItem, items: block.child.items }}
                                        />
                                      </div>
                                    );
                                  })}
                                {type === 'zert-block' &&  !el?.elementInfo?.approvedAt &&
                                  (findBorder || (block.parentItemCreate &&
                                      block.parentItemCreate.elementInfo.identifier === el.elementInfo.identifier)) &&
                                  block.child?.items.length === 0 && (
                                    <BlockItems
                                      blockAddLast
                                      createElement
                                      expandedToolbarProps={block.expandedToolbarProps}
                                      index={indexCreateElement}
                                      selectedRange={selectedRange}
                                      setSelectedRange={setSelectedRange}
                                      selected={selected}
                                      isLastItem
                                      parent={parent}
                                      parentChild={{ ...el, index: indexCreateElement, child: { items: arrayParent } }}
                                      investmentLevel={findInvestmentLevel}
                                      currentFocus={currentFocus}
                                      arrayParent={block.child?.items || []}
                                      looksState={looksState}
                                      parentItemCreate={dataParentItem}
                                    />
                                  )}
                              </div>
                              {block.colapse && type === 'zert-block' && (
                                <div className={cl.expandedWrapper}>
                                  <div className={cl.expandedItem} />
                                  <div className={cl.expandedItem} />
                                  <div className={cl.expandedItem} />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {block?.expandedToolbarBottom && (
                    <>
                      <AddToolbar setSelectedRange={setSelectedRange} parentItemCreate={parentItemCreate} />
                      {block.createType && (
                        <CreateBlockItems
                          parentItemCreate={parentItemCreate}
                          context={context}
                          setSelectedRange={setSelectedRange}
                          createType={block.createType}
                          props={props}
                        />
                      )}
                    </>
                  )}
                  <div className={cl.border}>{block.isHoveredAddBottom && !block.expandedToolbarBottom && <div />}</div>
                </div>
              </div>
            </div>
          </div>
        </Stack>
      </AddItemToList>
    </BlockItemsContext.Provider>
  );
}
export default BlockItems;
