import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import 'date-fns';
import Modal from '@zert-packages/components/shared/Modal/Modal';
import { AppBar, Tab, Tabs } from '@mui/material';
import TabPanel from '@zert-packages/components/common/TabPanel';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import SaveConfirmDialog from '@zert-packages/components/common/dialogs/SaveConfirmDialog';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import { useDispatch } from 'react-redux';
import { CompanyTab } from './CompanyTab';
import { PermissionTab } from './PermissionsTab';
import { ContextProvider } from './ContextProvider';
import { ScedulersTab } from './ScedulersTab';

export default function CompanyView({ intl, companyId, confirmCompany }) {
  const [isStoreCompany, setStoreCompany] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const tabCompany = useRef();
  const tabPermit = useRef();
  const tabScedulers = useRef();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const handleSave = () => {
    if (selectedTab === 0) {
      tabCompany.current.store();
      setIsSomethingChanged(false);
    } else if (selectedTab === 1) {
      tabPermit.current.store();
      setIsSomethingChanged(false);
    } else if (selectedTab === 2) {
      tabScedulers.current.store();
      setIsSomethingChanged(false);
    }
    return true;
  };

  const handleCancel = () => {
    setIsSomethingChanged(false);
    setErrors({});
  };
  const valuesSettingsContext = {
    errors,
    handleSave,
    handleCancel,
    setStoreCompany,
    setIsSomethingChanged
  };

  const cancelCompany = () => {
    confirmCompany();
  };

  const handleChangeSelectedTab = (event, newSelectedTab) => {
    function callbackClickYes() {
      const isSuccessful = handleSave();
      if (isSuccessful) setSelectedTab(newSelectedTab);
    }

    function callbackClickNo() {
      handleCancel();
      setSelectedTab(newSelectedTab);
    }

    if (isSomethingChanged) {
      dialogCustom(SaveConfirmDialog, {
        callbackClickYes,
        callbackClickNo
      });
    } else {
      setSelectedTab(newSelectedTab);
    }
  };

  return (
    <ContextProvider values={valuesSettingsContext}>
      {isStoreCompany && (
        <Modal show>
          <Loader loaderAction={LoaderAction.Storing}>
            {' '}
            <FormattedMessage id="company.storing" defaultMessage="Company" />{' '}
          </Loader>
        </Modal>
      )}
      <div className="mail-settings__container">
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={<FormattedMessage id="CompanyView.TabPanel.General" defaultMessage="General" />} value={0} />

            <Tab
              label={<FormattedMessage id="CompanyView.TabPanel.Permissions" defaultMessage="Permissions" />}
              value={1}
            />

            <Tab
              label={<FormattedMessage id="CompanyView.TabPanel.Scheduler" defaultMessage="Scheduler" />}
              value={2}
            />
          </Tabs>
        </AppBar>
        <TabPanel overflow="auto" index={0} value={selectedTab}>
          <CompanyTab ref={tabCompany} companyId={companyId} intl={intl} backFunction={confirmCompany} />
        </TabPanel>

        <TabPanel index={1} overflow="auto" value={selectedTab}>
          <PermissionTab ref={tabPermit} companyId={companyId} intl={intl} backFunction={confirmCompany} />
        </TabPanel>

        <TabPanel index={2} overflow="unset" value={selectedTab}>
          <ScedulersTab ref={tabScedulers} companyId={companyId} intl={intl} backFunction={confirmCompany} />
        </TabPanel>
      </div>
    </ContextProvider>
  );
}
