import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  wrapperInfo: {
    display: "grid",
    gridTemplateColumns: "42px 1fr",
    gridColumnGap: 10
  },
  icon: {
    fontSize: 42,
    color: "#4db1d3"
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30,
    justifyContent: "end",
    gap: 10
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  },

}));

const RemoveWorkingCopyDialog = ({ values }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [warning, setWarning] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const hardening = () => {
    setWarning(false);
  };

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}> <FormattedMessage id="Block.ElementMainTile.Text.Information"
                                                    defaultMessage="Information" />, </span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div>
      {warning ? <div>
        <FormattedMessage id="Block.ElementTile.Text.RemoveWorking" defaultMessage="Remove working copy" />,
        <FormattedMessage id="Block.ElementText.Text.RemoveWorking"
                          defaultMessage="Do you really want to remove the working version of selected element?" />
      </div> : <div className={cl.wrapperInfo}>
        <InfoIcon className={cl.icon} />
        <div>
          <span>
           <strong><FormattedMessage id="Block.ElementMainTile.Text.Information" defaultMessage="Information" /></strong>
          </span>
          <br/>
          <span>
            <FormattedMessage id="Block.ElementMainTile.Text.Element" defaultMessage="Element" />
          </span> {values.valuesName}
          <FormattedMessage id="Block.ElementMainTile.Text.info" defaultMessage="  1 other element(s) and can't be removed." />
        </div>
      </div>}
    </div>
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={hardening}>
        Yes
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        No
      </Button>
    </div>
  </Dialog>);
};

export default RemoveWorkingCopyDialog;