import { FormattedMessage } from 'react-intl';
import React, { useState, Fragment } from 'react';
import dialogCustom from '@zert-packages/components/common/dialogCustom';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import CloseIcon from '@mui/icons-material/Close';
import {
  handlePost,
  handlePostFileAsResult,
  handleQueryFileAsResult,
  openReport,
  openReportNew
} from '@zert-packages/actions/api';

const startQuery = '/demandProjects';
const createData = (left, right) => ({
  left,
  right
});

export const commentAPI = (viewId, versionId, uuid, comment) =>
  handlePost(`${startQuery}/updateLogWithComment/${uuid}/${versionId}`, createData(viewId, comment));

function CloseButton({ onClick, htmlColor = 'white' }) {
  return (
    <IconButton size="small" onClick={onClick}>
      <CloseIcon htmlColor={htmlColor} />
    </IconButton>
  );
}

function CommentDialog({ comment, onSend, handleClose }) {
  const [description, setDescription] = useState('');
  const onChangeData = (e) => {
    setDescription(e.target.value);
    comment.comment = e.target.value;
  };
  return (
    <Dialog fullWidth maxWidth="md" open onClose={handleClose}>
      <DialogTitle>
        {' '}
        <FormattedMessage id="send.comment" defaultMessage="Send comment" />
      </DialogTitle>
      <DialogContent>
        <div className="error-global-fallback-component__dialog-container">
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={`${comment.log}: ${comment.actionDetailed.join(' ')}`}
            defaultValue={`${comment.log}: ${comment.actionDetailed.join(' ')}`}
          />
          <TextField
            fullWidth
            multiline
            variant="outlined"
            label="Description"
            name="description"
            value={description}
            onChange={onChangeData}
            rows={8}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            onSend();
            handleClose();
          }}
          color="primary"
          autoFocus
          variant="outlined"
        >
          <FormattedMessage id="RMP.Comment.Send" defaultMessage="Send" />
        </Button>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="RMP.Comment.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const showCommentSnack = (hooks, comments, versionId, viewId) => {
  const snackbar = {};
  const onClickEdit = (comment) =>
    dialogCustom(CommentDialog, {
      comment,
      onSend: () => commentAPI(viewId, versionId, comment.uuid, comment.comment)
    });
  if (hooks.snackbar && comments) {
    comments.map((comment, index) => {
      const snackbarKey = new Date().valueOf() + index;
      snackbar[snackbarKey] = hooks.snackbar.enqueueSnackbar(`${comment.log}: ${comment.actionDetailed.join(' ')}`, {
        key: snackbarKey,
        variant: 'info',
        persist: false,
        action: (
          <>
            <Button size="small" onClick={() => onClickEdit(comment)}>
              <FormattedMessage id="RMP.Comment.AddComment" defaultMessage="Add Comment" />
            </Button>
            <CloseButton
              onClick={() => {
                console.log(hooks, snackbar);
                hooks.snackbar.closeSnackbar(snackbar[snackbarKey]);
              }}
            />
          </>
        )
      });
    });
  }
};

export default showCommentSnack;

const WhiteCircularProgress = withStyles({
  root: {
    marginRight: 10
  },
  colorPrimary: {
    color: 'white'
  }
})(CircularProgress);

export const createInProgressSnack = (snackbar, message) => {
   return snackbar.enqueueSnackbar(message, {
    variant: 'info',
    persist: true,
    action: (key) => (
      <>
        <WhiteCircularProgress size={20} />
        <CloseButton onClick={() => snackbar.closeSnackbar(key)} />
      </>
    )
  })
};

const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (blob, fileName, isOpen, mymeType) {
    // var// json = JSON.stringify(data),
    // blob = new Blob([json], {type: "octet/stream"}),
    const url = window.URL.createObjectURL(new Blob([blob], { type: mymeType }));
    //   url = window.URL.createObjectURL(blob);
    a.href = url;
    isOpen ? a.removeAttribute('download') : (a.download = fileName);
    a.target = '_blank';
    a.click();
    // window.open(url);
  //  window.URL.revokeObjectURL(url);
  };
})();

export const createOpenReportSnack = (snackbar, generatedReport, mimeType) => {
  const onClickOpenReport = (key, isOpen) => async () => {
    let reportUUID;
    let reportName;

    if (typeof generatedReport === 'string' || generatedReport instanceof String) {
      reportUUID = generatedReport;
      reportName = 'report';
    } else {
      reportUUID = generatedReport.reportUUID;
      reportName =
        (generatedReport.elementName ? `${generatedReport.elementName} - ` : '') +
        (generatedReport.methodName ? `${generatedReport.methodName} - ` : '') +
        (generatedReport.name ? `${generatedReport.name} - ` : '') +
        (generatedReport.elementStatus ? `${generatedReport.elementStatus} - ` : '') +
        new Date().toISOString().slice(0, 10);
    }

    const blob = await handleQueryFileAsResult(`${openReportNew + reportUUID}/report/${mimeType}`);
    saveData(
      blob,
      `${reportName}.${mimeType}`,
      isOpen,
      mimeType == 'pdf' ? 'application/pdf' : mimeType == 'zip' ? 'application/zip' : 'application/vnd.ms-excel'
    );
  };

  return snackbar.enqueueSnackbar(
    <FormattedMessage id="RMP.SnackBar.ReportHasBeenGenerated.Label" defaultMessage="Your report has been generated" />,
    {
      variant: 'info',
      persist: true,
      action: (key) => (
        <>
          <Button
            size="small"
            variant="outlined"
            style={{
              color: 'white',
              border: '1px solid white',
              marginRight: '5px'
            }}
            onClick={onClickOpenReport(key, true)}
          >
            <FormattedMessage id="RMP.SnackBar.ReportHasBeenGenerated.OpenButton" defaultMessage="Open" />
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{
              color: 'white',
              border: '1px solid white',
              marginRight: '5px'
            }}
            onClick={onClickOpenReport(key, false)}
          >
            <FormattedMessage id="RMP.SnackBar.ReportHasBeenGenerated.DownloadButton" defaultMessage="Download" />
          </Button>
          <CloseButton onClick={() => snackbar.closeSnackbar(key)} />
        </>
      )
    }
  );
};
